import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import peace from "../../assets/images/icons/peacevillage.png";
import addpic from "../../assets/images/icons/addpic.png";
import { Link, Navigate } from "react-router-dom";
import '../BDO/Dialysis/DialysisForm.css'
import { useAlert } from "react-alert";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import { APIURL } from "../../Global";
import SubmitConfirmationPopup from "../common/SubmitConfirm";
import { ConfirmationContext } from "../contexts/SubmitConfirmationContext";
import { IoIosCloseCircleOutline } from "react-icons/io";
import DatePicker from 'react-date-picker';
import Select from 'react-select';
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { MdOutlineFileUpload } from "react-icons/md";
import SuccessModal from "../PhysioTherappy/SuccessModal";
function Donation() {
    const [showConfirmation, setShowConfirmation, closeConfirmationPopup] = useContext(ConfirmationContext);
   
    const [error, setError] = useState({})
    const [fileName,setFileName]=useState("Upload ID");
    const [show, setShow] = useState(false)
    const [modalDetails, setModalDetails] = useState('')
    const [successSubmit, setSuccessSubmit] = useState(null);
    const alert = useAlert();
    const inputRefs = useRef([])
    const submitButtonRef = useRef(null)
    const navigate = useNavigate();

    const [isValidIdentity, setIsValidIdentity] = useState(true);
    const [fileNames, setFileNames] = useState({
        referral_document: "Upload File",
        medical_prescription: "Upload File",
    });
  
    const [userDatas, setUserDatas] = useState({
       donor_name:"",
       amount:null,
       donation_to:"shelter",
        identitycard: { value: '', label: '' },
       address:"",
       contact:"91",
      email:"",
        img_url: "",
        currency:"",
        id_card:""
       

    });

    const identityoptions = [
        { value: '', label: 'Select type' },
        { value: 'pan', label: 'PAN' },
        { value: 'aadhar', label: 'AADHAR' },
       ]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);
    const handleKeyDown = (e, index) => {
        if (e.key === "Enter") {
            e.preventDefault()
            if (index === 19) {
                inputRefs.current[20].click();
            } else if (index === 32) {
                submitButtonRef.current.click();
            } else {
                e.preventDefault();
                const nextIndex = index + 1;
                if (nextIndex < inputRefs.current.length && inputRefs.current[nextIndex]) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };

    const handleKey = (e) => {
        if (e.altKey && e.key === 's') {
            submitButtonRef.current.click();
        } else if (e.altKey && e.key === 'c') {
            navigate("../");
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleKey);

        return () => {
            document.removeEventListener("keydown", handleKey);
        };
    }, []);

    const trimspace = (val) => {

        return val.startsWith(' ') ? val.trimLeft() : val;
    }

    const handleChange = (e) => {
        e.preventDefault();
        const val = trimspace(e.target.value);
        const key = e.target.name;

        setError((currentstate) => ({
            ...currentstate,
            [key]: "",
        }));
if(key==="amount"){
    if(val>0||val===""){
        setUserDatas(currentstate => ({
            ...currentstate,
            [key]: val
        }));
    }else{
        setUserDatas(currentstate => ({
            ...currentstate,
            [key]: ""
        }));
        setError((currentstate) => ({
            ...currentstate,
            [key]: "Enter valid amount",
        }));
    }
}else{
        setUserDatas(currentstate => ({
            ...currentstate,
            [key]: val
        }));

    }
       

    }

    const IdentityChange = (data) => {

        setUserDatas(current => ({
            ...current, identitycard: data,
            identitynumber: ''
        }))

        setError((currentstate) => ({
            ...currentstate,
            identitynumber: '',
        }))

        if (data.value !== '') {
            if (!userDatas.identitynumber || userDatas.identitynumber === '' || userDatas.identitynumber === null) {
                setError((currentstate) => ({
                    ...currentstate,
                    identitynumber: 'Upload identity card',
                }))
                setFileName("Upload ID")
                setUserDatas(current => ({
                    ...current, id_card: ''
                }))

            }
        } else if (data.value === '') {
            setUserDatas(current => ({
                ...current, id_card: ''
            }))
            setFileName("Upload ID")
            setError((currentstate) => ({
                ...currentstate,
                identitynumber: '',
            }))
        }
    }

  
    const handleGenderRadioButton = (e) => {
        let genderOpted = e.target.value;
        setUserDatas(currentstate => ({
            ...currentstate,
           donation_to: genderOpted

        }))
        setError(currentstate => ({
            ...currentstate,
            donation_to: ""
        }))
    }

    const handlePhoneInput = (value, data, event, formattedValue) => {
       
        setError({ ...error, contact: null });
        setUserDatas(currentstate => ({
            ...currentstate,
          contact:value
        }));

        if (value.length === 0 && value.length === 2) {
            setError({ ...error, contact: "" });
            setUserDatas(currentstate => ({
                ...currentstate,
                contact:91
            }));
        }
    }
  

    const validate = () => {
        let input = userDatas;
        let errors = {};
        let isValid = true;

        setError({});

        if (!input["donor_name"] || input["donor_name"] === "") {
            isValid = false;
            errors.donor_name = 'Name is required';
        }
        if (!input["amount"] || input["amount"] === ""|| input["amount"]<1) {
            isValid = false;
            errors.amount ='Amount is required';
        }
        if (input["amount"] && input["amount"] >9999&&(userDatas.identitycard.value === "" || userDatas.identitycard.value === null ||userDatas.identitycard.value === undefined)) {
            isValid = false;
            errors.identity ='ID card details required';
        }
        if (input["email"] !==""&&input["email"] !==null ) {
            var pattern = new RegExp(
              /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(input["email"])) {
              isValid = false;
              errors["email"] = "Enter a valid email address.";
            }
          }
   
            if (!input.contact || input.contact === ""||input.contact === "91") {
                isValid = false;
                if (!errors.contact) errors.contact = {};
                errors.contact = 'Mobile number is required';
            }
        

      

        if (!input["donation_to"] || input["donation_to"] === "") {
            isValid = false;
            errors.donation_to = 'donation_to is required';
        }

      
        if (userDatas.identitycard.value !== "" && userDatas.identitycard.value !== null && userDatas.identitycard.value !== undefined) {
            if (!input["id_card"] || input["id_card"] === "") {
                isValid = false;
                errors.identitynumber = 'Upload ID';
            }
        }
       
              if (!input["contact"] || input["contact"] === "91") {
            isValid = false;
            errors.contact = 'Mobile number is required';
        }
        if (input["contact"]&&input["contact"].length > 2 && input["contact"].length < 12) {
            isValid = false;
            errors.contact = 'Check number of digits';
        }
       
        
        // if (userDatas.contact&&userDatas.contact !== '' && userDatas.contact !== '91' && userDatas.contact.length < 12) {
        //     isValid = false;
        //     errors.phone = 'check number of digits';
        // }
        setError(errors);

        if (isValid) {
            setShowConfirmation(true);
        }
    };


    const handleConfirm = () => {
        closeConfirmationPopup()
        handleRegister("No");
    };

    const handleCancel = () => {
        
        closeConfirmationPopup()
    };

    const SubmitSuccessPopup = (props) => {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
          >
            
            <Modal.Body className="text-center">
              <h4>Your generosity will create meaningful change, and for that, we are eternally grateful</h4>
              {userDatas.email&& <h6>The receipt will be sent to {userDatas.email} shortly </h6>}
              <div style={{marginTop:"1%"}}><a href="https://peacevillage.in/" rel="noopener noreferrer">
                <Button className="btn btn-primary btn-col">Close</Button>
              </a></div>
            </Modal.Body>
           
          </Modal>
        );
      };
    

      const handleRegister = async (shouldSaveAnyway) => {
        let orderID = "";
        let RAZOR_PAY_KEY = userDatas.donation_to === "shelter" ? "rzp_test_zFH7qgljaz9SDv" : "rzp_test_0Y7cFwloK8uUzs";
        
        const formData = new FormData();
        formData.append('donor_name', userDatas.donor_name);
        formData.append('donation_to', userDatas.donation_to);
        formData.append('amount', userDatas.amount);
        if (userDatas.identitycard.value) formData.append('idcard_type', userDatas.identitycard.value);
        if (userDatas.id_card) formData.append('id_card', userDatas.id_card);
        formData.append('contact', userDatas.contact);
        if (userDatas.email) formData.append('email', userDatas.email);
        if (userDatas.address) formData.append('address', userDatas.address);
        formData.append('currency', "INR");
    
        const token = sessionStorage.getItem("usertoken") || null;
        const headers = {
            "Content-Type": "application/json",
        };
    
        try {
            const response = await axios.post(
                `${APIURL}/api/v1/payment/donation-order/`,
                formData,
                { headers }
            );
    
            if (response.data.status === "success") {
                console.log("Create order value", response.data);
    
                if (response.data.message && response.data.message === 'payment complete') {
                    alert.success(response.data.message);
                    // Handle success case like updating user details
                } else {
                    orderID = response.data.order_id;
                    let options = {
                        key: RAZOR_PAY_KEY,
                        order_id: orderID,
                        currency: "INR",
                        name: "Felixa Care",
                        description: "Felixa Care, A complete HealthCare Solution",
                        handler: function (response) {
                            console.log("Response from Razorpay", response);
    
                            const requestBody = {
                                razorpay_order_id: response.razorpay_order_id,
                                razorpay_payment_id: response.razorpay_payment_id,
                            };
    
                            axios.post(
                                `${APIURL}/api/v1/payment/razorpay-transaction/`,
                                requestBody,
                                { headers }
                            )
                            .then((response) => {
                                console.log(response.data);
                                setSuccessSubmit(true);  // Assuming this is for redirecting or showing success
                            })
                            .catch((error) => {
                                alert.error("There was an error in Razorpay payment verification", error);
                                // Handle error appropriately
                            });
                        },
                        prefill: {
                            name: userDatas.donor_name || "",
                            email: userDatas.email || "",
                            contact: userDatas.contact || ""
                        },
                        notes: {
                            address: userDatas.address || "Bangalore, Kerala",
                        },
                        theme: {
                            color: "green",
                        },
                    };
    
                    const pay = new window.Razorpay(options);
                    pay.open();
                }
            }
    
        } catch (error) {
            console.error("Error during the donation process", error);
            alert.error("Something went wrong! Please try again later.");
        }
    };
    
       

    const handleClose = () => {
        setShow(false)
        window.location.reload();
    }
    const reader = new FileReader();
    const uploadImage1 = async (e) => {
      
      const key = e.target.name;
      const file = e.target.files[0];
      const imageUrl = URL.createObjectURL(file);
    
      if (!file) {
        alert.error("Please Select a File");
        setFileName("Upload File");
        return;
      }
    
      if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|pdf|PDF)$/)) {
        alert.error("Select a valid image or PDF file");
        setFileName("Upload File");
        return;
      }
    
      if (file.size > 10e6) {
        alert.error("Select a file smaller than 10MB");
        setFileName("Upload File");
        return;
      }
     
        const shortFileName = file.name.length > 10 ? file.name.slice(0, 10) + '...' : file.name;
    
        if (file.type.startsWith('image/')) {
          setFileName(shortFileName);
          setError((currentstate) => ({
            ...currentstate,
            identitynumber: '',
        }))
          setUserDatas((currentData) => ({
            ...currentData,
           id_card: file, // Store the base64 encoded image data
            imageUrl1: imageUrl, // Optional for image preview
          }));
        }  if (file.type === 'application/pdf') {
        // Handle PDF file
        setFileName(file.name);
        setError((currentstate) => ({
            ...currentstate,
            identitynumber: '',
        }))
        setUserDatas((currentData) => ({
          ...currentData,
          id_card: file,
          imageUrl: imageUrl,
        }));
      
      
    }
    };

    return (
        <div className="row">
        <div className='dialysis-container col-lg-7 col-md-9 col-sm-11' style={{margin:"auto",marginTop:"1%",marginBottom:"1%"}}>
            <div className='row title' >
                <img src={peace} className="peace-image col-2" alt="Peace Village" />
                <h4  className='dialysis-head col-7'>Donation form</h4>
            </div>
            <div className="row side-head">
                <div className="col-12">
                    <hr className="lines" />
                </div>
            </div>
            <div className='dialysis-formelements'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='input-container'>
                            <label className={error.amount? 'error-label' : ''}>
                                {error.amount || <><b>Amount</b><span> *</span></>}
                            </label>
                            <input 

                                className={`input1 ${error.amount ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[0] = el}
                                onKeyDown={(e) => handleKeyDown(e, 0)}
                                type="number"
                                maxLength="50"
                                name="amount"
                                value={userDatas.amount}
                                onChange={handleChange}
                                style={{border:error.amount? '2px solid red':"2px solid black",fontWeight:"bold"}}
                            />
                        </div>
                        </div>
                        <div className="col-12" style={{margin:"auto",}}>
                        <div className='input-container'>
                            <label className={error.donation_to ? 'error-label' : ''}>
                                {error.donation_to || <>Donation for<span> *</span></>}
                            </label>
                            <div className={`input1 ${error.donation_to ? 'error-border' : ''}`}>
                                <div className="col-4">
                                    <input
                                        type="radio"
                                        id="shelter"
                                        name="donation_to"
                                        value="shelter"
                                        checked={userDatas.donation_to === "shelter"}
                                        onClick={handleGenderRadioButton}
                                    />
                                    <label htmlFor="shelter_home">Shelter home</label>
                                </div>
                                <div className="col-2">
                                    {/* <input
                                    disabled
                                        type="radio"
                                        id="paliative"
                                        name="donation_to"
                                        value="paliative"
                                        checked={userDatas.donation_to === "paliative"}
                                        onClick={handleGenderRadioButton}
                                    />
                                    <label htmlFor="paliative">Paliative</label> */}
                                </div>
                                <div className="col-4">
                                    <input
                                        type="radio"
                                        id="trust"
                                        name="donation_to"
                                        value="trust"
                                        checked={userDatas.donation_to === "trust"}
                                        onClick={handleGenderRadioButton}
                                    />
                                    <label htmlFor="trust">Trust</label>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='input-container'>
                            <label className={error.donor_name ? 'error-label' : ''}>
                                {error.donor_name || <>Name<span> *</span></>}
                            </label>
                            <input
                                className={`input1 ${error.donor_name ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[0] = el}
                                onKeyDown={(e) => handleKeyDown(e, 0)}
                                type="text"
                                maxLength="50"
                                name="donor_name"
                                value={userDatas.donor_name}
                                onChange={handleChange}
                                
                            />
                        </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='input-container'>
                            <label className={error.contact ? 'error-label' : ''}>
                                {error.contact || <>Contact no<span> *</span></>}
                            </label>
                            <PhoneInput
                                style={{ border: error.contact ? '1px solid red' : 'none', borderRadius: '10px' }}
                                inputProps={{
                                    ref: el => inputRefs.current[2] = el,
                                    tabIndex: 0,
                                    onKeyDown: e => handleKeyDown(e, 2),
                                }}
                                value={userDatas.contact}
                                country={"in"}
                                onlyCountries={['in']}
                                countryCodeEditable={false}
                                onChange={handlePhoneInput}
                            />
                        </div>
                        </div>
                        
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='input-container'>
                        <label className={error.email ? 'error-label' : ''}>
                                {error.email || <>Email  for receipt</>}
                            </label>
                            <input
                            //  style={{ border: error.email ? '1px solid red' : 'none', borderRadius: '10px' }}
                             className={`input1 ${error.email? 'error-border' : ''}`}
                                ref={el => inputRefs.current[1] = el}
                                onKeyDown={(e) => handleKeyDown(e, 1)}
                                type="text"
                                maxLength="50"
                                value={userDatas.email}
                                name="email"
                                onChange={handleChange}
                            />
                        </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='input-container'>
                        <label className={error.identity ? 'error-label' : ''}>
                                {error.identity || <>ID Card Type<span>{userDatas.amount>9999&&" *"}</span></>}
                            </label>
                            
                            <Select
                            style={{ border: error.identity ? '1px solid red' : 'none', borderRadius: '10px', width: "100%" }}
                                ref={el => inputRefs.current[11] = el}
                                onKeyDown={(e) => handleKeyDown(e, 11)}
                                value={userDatas.identitycard.value !== "" ? userDatas.identitycard : "Select type"}
                                onChange={(data, e) => { IdentityChange(data, e) }}
                                options={identityoptions}
                                name='identitycard'
                               
                            />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='input-container'>
                            <label className={error.identitynumber ? 'error-label' : ''}>
                                {error.identitynumber || <>ID Card</>}
                            </label>
                            <label style={{ backgroundColor: "white", border: "1px dashed #104173", height: "40px", width: "98%", borderRadius: "10px", display: "flex", alignItems: "center", justifyContent: "center", gap: "8px", padding: "0 12px", cursor:userDatas.identitycard.value===""?"not-allowed": "pointer" }}>
                    <input disabled={userDatas.identitycard.value===""} type="file" name="id_card" style={{ display: "none" }} onChange={uploadImage1} />
                    {fileName==="Upload ID"?
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1.5"><path d="M17 9.002c2.175.012 3.353.109 4.121.877C22 10.758 22 12.172 22 15v1c0 2.829 0 4.243-.879 5.122C20.243 22 18.828 22 16 22H8c-2.828 0-4.243 0-5.121-.878C2 20.242 2 18.829 2 16v-1c0-2.828 0-4.242.879-5.121c.768-.768 1.946-.865 4.121-.877" opacity="0.5"/><path stroke-linejoin="round" d="M12 15V2m0 0l3 3.5M12 2L9 5.5"/></g></svg>:
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M14 22h-4c-3.771 0-5.657 0-6.828-1.172S2 17.771 2 14v-4c0-3.771 0-5.657 1.172-6.828S6.239 2 10.03 2c.606 0 1.091 0 1.5.017q-.02.12-.02.244l-.01 2.834c0 1.097 0 2.067.105 2.848c.114.847.375 1.694 1.067 2.386c.69.69 1.538.952 2.385 1.066c.781.105 1.751.105 2.848.105h4.052c.043.534.043 1.19.043 2.063V14c0 3.771 0 5.657-1.172 6.828S17.771 22 14 22" clip-rule="evenodd" opacity="0.5"/><path fill="green" d="M10.56 15.498a.75.75 0 1 0-1.12-.996l-2.107 2.37l-.772-.87a.75.75 0 0 0-1.122.996l1.334 1.5a.75.75 0 0 0 1.12 0zm.95-13.238l-.01 2.835c0 1.097 0 2.066.105 2.848c.114.847.375 1.694 1.067 2.385c.69.691 1.538.953 2.385 1.067c.781.105 1.751.105 2.848.105h4.052q.02.232.028.5H22c0-.268 0-.402-.01-.56a5.3 5.3 0 0 0-.958-2.641c-.094-.128-.158-.204-.285-.357C19.954 7.494 18.91 6.312 18 5.5c-.81-.724-1.921-1.515-2.89-2.161c-.832-.556-1.248-.834-1.819-1.04a6 6 0 0 0-.506-.154c-.384-.095-.758-.128-1.285-.14z"/></svg>} 
                    {fileName} 
                  </label> 
                        </div>
                    </div>
                        <div className='col-12'>
                        <div className='input-container'>
                        <label className={error.address ? 'error-label' : ''}>
                                {error.address || <>Address</>}
                            </label>
                            <textarea
                                className='input1'
                                ref={el => inputRefs.current[1] = el}
                                onKeyDown={(e) => handleKeyDown(e, 1)}
                                type="text"
                                maxLength="150"
                                value={userDatas.address}
                                name="address"
                                style={{height:"100px"}}
                                onChange={handleChange}
                            />
                        </div>
                        </div>
                       
                  
                </div>
             
              
               
               
                
             
        
                <div className="row" style={{paddingTop:"1%"}}>
                    <button className="save" ref={submitButtonRef} onClick={() => { validate() }}>Submit</button>
                </div>
            </div>
            {successSubmit ? (
        <SubmitSuccessPopup
          show={successSubmit}
          onHide={() => setSuccessSubmit(false)}
        //   setemailshow={email}
        />
      ) : null}
            {
                showConfirmation && (
                    <SubmitConfirmationPopup
                        message="Are you sure you want to submit the form?"
                        onConfirm={handleConfirm}
                        onCancel={handleCancel}
                    />
                )
            }
           
            {show && <SuccessModal
                handleClose={handleClose}
                message={modalDetails}
            />}
        </div >
        </div>
    );
}

export default Donation;
