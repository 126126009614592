import React, { useState, useEffect, useRef, useContext } from 'react'
import './csc.css'
import axios from "axios";
import { APIURL } from "../../../Global";

function DialysisOpSearch({ selectedPatient, setSelectedPatient, selectedMachine, setSelectedMachine, selected }) {
    const [searchTerm, setSearchTerm] = useState("")
    const [searchTerm1, setSearchTerm1] = useState("")
    const [machineList, setMachineList] = useState([])
    const [PatientList, setPatientList] = useState([])
    const [display, setDisplay] = useState(false)
    const dropdownRef = useRef(null);

    useEffect(() => {
        setMachineList([])
        machineSearch(searchTerm)
    }, [selected])

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDisplay(false);
            setSearchTerm1('')
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleMachineSearch = e => {
        setSelectedMachine('')
        setMachineList([])
        setSearchTerm(e.target.value)
        machineSearch(e.target.value)
    }

    const handlePatientSearch = (e) => {
        if (e.target.value === '') {
            setDisplay(false)
        }
        setPatientList([])
        setSearchTerm1(e.target.value)
        patientSearch(e.target.value)
    }

    const machineSearch = (val) => {

        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
        axios
            .get(`${APIURL}/api/v1/service-provider/dialysis-unit/?is_active=True&is_standby=False&search=${val}`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {

                if (res.data.status === "success") {
                    const pageData = res.data.message
                    setMachineList(pageData)
                }
            })
            .catch((err) => {

            });
    }

    const patientSearch = (val) => {
        console.log(val)
        const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (val === "") {
            setPatientList([])
        }
        else {

            const tokenString = sessionStorage.getItem("usertoken");
            let v2 = tokenString.replace(/["]+/g, "");
            axios
                .get(`${APIURL}/api/v1/service-provider/dialysis-queue/?eligibility=approved&search=${val}`, {
                    headers: { Authorization: "Token " + v2 },
                })
                .then((res) => {
                    if (res.data.status === "success") {
                        setPatientList(res.data.data)
                        if (res.data.data.length > 0) {
                            setDisplay(true)
                        }
                    }
                    else {
                    }
                }).catch((error) => {

                })
        }
    }
    return (
        <>
            <div className="col-lg-3 col-md-3 container">
                <div className='side-box'>
                    <div className='sidebox-search-input-container'>
                        <input
                            className='sidebox-search-input'
                            type='text'
                            value={searchTerm1}
                            placeholder='Search Patient'
                            onChange={handlePatientSearch} />
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4374 15.7502H16.5487L16.2337 15.4464C16.9367 14.6296 17.4506 13.6675 17.7384 12.629C18.0262 11.5904 18.081 10.5011 17.8987 9.43891C17.3699 6.31141 14.7599 3.8139 11.6099 3.4314C10.5025 3.2913 9.37767 3.4064 8.32156 3.76788C7.26546 4.12936 6.30604 4.72765 5.51672 5.51697C4.72741 6.30628 4.12912 7.2657 3.76764 8.32181C3.40615 9.37792 3.29106 10.5027 3.43116 11.6102C3.81366 14.7602 6.31116 17.3702 9.43866 17.8989C10.5008 18.0812 11.5902 18.0265 12.6287 17.7386C13.6673 17.4508 14.6294 16.937 15.4462 16.2339L15.7499 16.5489V17.4377L20.5312 22.2189C20.9924 22.6802 21.7462 22.6802 22.2074 22.2189C22.6687 21.7577 22.6687 21.0039 22.2074 20.5427L17.4374 15.7502ZM10.6874 15.7502C7.88616 15.7502 5.62491 13.4889 5.62491 10.6877C5.62491 7.8864 7.88616 5.62515 10.6874 5.62515C13.4887 5.62515 15.7499 7.8864 15.7499 10.6877C15.7499 13.4889 13.4887 15.7502 10.6874 15.7502Z" fill="#5D5D5D" />
                        </svg>
                    </div>
                    {
                        display === true &&
                        <div ref={dropdownRef} className='suggest-display adjust-dimensions' style={{ width: "87%" }}>
                            {
                                PatientList.length > 0 ? PatientList.map((item, index) => {
                                    const applicantDetails = item.applicant_details;
                                    return (
                                        <p key={index} onClick={() => { setSelectedPatient(applicantDetails); setDisplay(false); setSearchTerm('') }} className='suggest-item text-secondary' style={{ color: '#000', display: 'flex', flexDirection: 'column', margin: 0 }}>
                                            <label style={{ textTransform: 'capitalize', margin: 0 }}>{applicantDetails.full_name}</label>
                                            <label style={{ margin: 0 }}>{applicantDetails.de_number}</label>
                                        </p>
                                    );
                                }) : <h6 style={{ textAlign: 'center' }}>No Patients found !!</h6>
                            }
                        </div>
                    }
                    {(selectedPatient !== '' && selectedPatient !== null && selectedPatient !== undefined) &&
                        <div style={{ width: "fit-content", margin: "auto", display: "flex", flexDirection: "column" }}>
                            <label style={{ margin: 0, textTransform: "capitalize", fontWeight: 500, color: "#2B69A3" }}>{selectedPatient.full_name}</label>
                            <label style={{ margin: 0, fontSize: "12px" }}>{selectedPatient.email && selectedPatient.email}</label>
                            <label style={{ margin: 0, fontSize: "12px" }}>
                                {selectedPatient.mobile_number &&
                                    (selectedPatient.mobile_number.length === 12
                                        ? '+' + selectedPatient.mobile_number
                                        : selectedPatient.mobile_number.length === 10
                                            ? '+91' + selectedPatient.mobile_number
                                            : selectedPatient.mobile_number)}
                            </label>
                        </div>}
                </div>
                <div className='side-box'>
                    <div className='side-box-head'>
                        <div><label>Machine List</label></div>
                        <div className='sidebox-search-input-container' style={{ backgroundColor: "#F5EFE8" }}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3851 15.4461C13.0371 16.5232 11.3278 17.0432 9.60827 16.8994C7.88877 16.7556 6.28961 15.9588 5.13922 14.6728C3.98883 13.3867 3.37455 11.709 3.42254 9.98415C3.47052 8.25932 4.17713 6.61834 5.39725 5.39822C6.61736 4.17811 8.25835 3.4715 9.98318 3.42351C11.708 3.37553 13.3857 3.98981 14.6718 5.1402C15.9579 6.29058 16.7546 7.88975 16.8984 9.60924C17.0422 11.3287 16.5222 13.038 15.4451 14.3861L20.6011 19.5411C20.6748 19.6097 20.7339 19.6925 20.7749 19.7845C20.8159 19.8765 20.8379 19.9758 20.8397 20.0765C20.8415 20.1772 20.8229 20.2773 20.7852 20.3707C20.7475 20.4641 20.6913 20.5489 20.6201 20.6201C20.5489 20.6913 20.4641 20.7475 20.3707 20.7852C20.2773 20.8229 20.1773 20.8414 20.0766 20.8397C19.9759 20.8379 19.8766 20.8158 19.7846 20.7748C19.6926 20.7339 19.6098 20.6748 19.5411 20.6011L14.3851 15.4461ZM6.46009 13.8841C5.72613 13.15 5.22624 12.2149 5.0236 11.1968C4.82096 10.1788 4.92466 9.1235 5.3216 8.16437C5.71854 7.20523 6.3909 6.38529 7.2537 5.80817C8.11651 5.23105 9.13103 4.92265 10.1691 4.92195C11.2071 4.92125 12.222 5.22828 13.0856 5.80424C13.9492 6.3802 14.6226 7.19924 15.0209 8.15784C15.4191 9.11644 15.5242 10.1716 15.323 11.1899C15.1217 12.2082 14.6231 13.144 13.8901 13.8791L13.8851 13.8841L13.8801 13.8881C12.8952 14.8707 11.5605 15.4222 10.1693 15.4214C8.77801 15.4207 7.44394 14.8678 6.46009 13.8841Z" fill="#777777" />
                            </svg>
                            <input
                                className='sidebox-search-input'
                                type='text'
                                placeholder='Search Machine'
                                value={searchTerm}
                                onChange={handleMachineSearch} />
                        </div>
                    </div>
                    <div className='doctor-list-container' id='machinelist'>
                        {machineList.length > 0 ? machineList.map((item, index) => {
                            return (
                                <div
                                    key={item.id}
                                    className={`csc-doctorlist ${selectedMachine === item ? 'selected-item' : ''}`}
                                    onClick={() => { setSelectedMachine(item) }}
                                >
                                    <div style={{ flex: 1 }}>
                                        <label className=""> {index + 1 + "."}</label>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: 'column', flex: 10 }}>
                                        <label className="head-style"> {item.unit_no}</label>
                                        <label className="content-style"> {item.model_name}</label>
                                    </div>
                                </div>
                            )
                        }) :
                            <div className="" style={{ marginTop: "10%", color: "red" }}>No Machines!!</div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default DialysisOpSearch;
