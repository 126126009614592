import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import sda from "../../assets/images/icons/sda.png";
import hospitalroom from "../../assets/images/icons/hospital-room.png";
import request from "../../assets/images/icons/request1.png";
import patientreg from "../../assets/images/icons/patientreg.png";
import feedback from "../../assets/images/icons/feedback1.png";
import admission from "../../assets/images/icons/admission.png";
import admissionreport from "../../assets/images/icons/admissionreport.png";
import patient from "../../assets/images/icons/patient.png";
import customer from "../../assets/images/icons/customercare.png";
import payment from "../../assets/images/icons/payment.png";
import inventory from "../../assets/images/inventory.jpg";
import volunteers from "../../assets/images/icons/volunteer.png";
import dialysis from "../../assets/images/icons/dialysis.png";
import discharge from "../../assets/images/discharge.jpg";
import PhysioSvg from "../PhysioTherappy/Physiosvg";
import PalliativeSvg from "../Palliative/palliativesvg";
import cirtificate from "../../assets/images/icons/report.png";
import calendar from "../../assets/images/icons/calendar3.png";
import wound from "../../assets/images/wound.png"
function RenderTiles({ access }) {
    const role = sessionStorage.getItem("usertype");
    const navigate = useNavigate();
    return (
        <div className="home-container">
            {(access.some(item => item.node_access === "0.1.6") && (role === 'hospital_bdo' || role === 'hospital_pro')) && (
                <div className="home-tiles" onClick={() => navigate("/admission")}>
                    <img src={admission} className="home-icon" width="65px" alt="Admissions" />
                    <h5 className="each-title">Admissions</h5>
                </div>
            )}
            {(access.some(item => item.node_access === "0.1.5") && (role === 'hospital_bdo' || role === 'hospital_pro')) && (
                <div className="home-tiles" onClick={() => navigate("/admissionreport")}>
                    <img src={admissionreport} className="home-icon" width="65px" alt="Reports" />
                    <h5 className="each-title">Reports</h5>
                </div>
            )}
            {(access.some(item => item.node_access === "0.1.14") && (role === 'hospital_bdo' || role === 'hospital_pro')) && (
                <div className="home-tiles" onClick={() => navigate("/death_report")}>
                    <img src={cirtificate} className="home-icon" width="65px" alt="Reports" />
                    <h5 className="each-title">Death Reports</h5>
                </div>
            )}
            {(access.some(item => item.node_access === "0.1.4") && (role === 'hospital_bdo' || role === 'hospital_pro' || role === 'hospital_doctor' || role === 'hospital_allied_prof' || role === 'hospital_nurse')) && (
                <div className="home-tiles" onClick={() => navigate("/patientlist")}>
                    <img src={patient} className="home-icon" width="65px" alt="Patient Records" />
                    <h5 className="each-title">Patient Records</h5>
                </div>
            )}
            {(access.some(item => ["0.1.7.2", "0.1.7"].includes(item.node_access)) && (role === 'hospital_bdo' || role === 'hospital_pro')) && (
                <div className="home-tiles" onClick={() => navigate("/assignpatient")}>
                    <img src={hospitalroom} className="home-icon" width="65px" alt="Facility Assignments" />
                    <h5 className="each-title">Facility Assignments</h5>
                </div>
            )}
            {(access.some(item => ["0.1.1.2", "0.1.2.2", "0.1.3.2", "0.1.8.1", "0.1.1.4"].includes(item.node_access)) && (role === 'hospital_bdo' || role === 'hospital_front_office')) && (
                <div className="home-tiles" onClick={() => navigate("/customercare")}>
                    <img src={customer} className="home-icon" width="65px" alt="Customer Service Centre" />
                    <h6 className="each-title">Customer Service Centre</h6>
                </div>
            )}
            {(access.some(item => ["0.1.2.2", "0.1.8.1"].includes(item.node_access)) && (role === 'hospital_doctor')) && (
                <div className="home-tiles" onClick={() => navigate("/bookings")}>
                    <img src={customer} className="home-icon" width="65px" alt="Customer Service Centre" />
                    <h6 className="each-title">Appointments</h6>
                </div>
            )}
            {(access.some(item => ["0.1.3.2"].includes(item.node_access)) && (role === 'hospital_allied_prof')) &&
                <div className="home-tiles" onClick={() => navigate("/bookings")}>
                    <img src={customer} className="home-icon" width="65px" />
                    <h6 className="each-title"> Appointments </h6>
                </div>
            }
            {(access.some(item => ["0.1.13.2", "0.1.13"].includes(item.node_access)) && (role === 'hospital_bdo' || role === 'hospital_pro')) && (
                <div className="home-tiles" onClick={() => navigate("/feedback")}>
                    <img src={feedback} className="home-icon" width="65px" alt="feedback" />
                    <h5 className="each-title">Customer feedback</h5>
                </div>
            )}
            {(access.some(item => ["0.1.8.2", "0.1.8"].includes(item.node_access)) && (role === 'hospital_bdo' || role === 'hospital_pro')) && (
                <div className="home-tiles" onClick={() => navigate("/register-patient")}>
                    <img src={patientreg} className="home-icon" width="65px" alt="patinet registration" />
                    <h5 className="each-title">Patient registration</h5>
                </div>
            )}
            {(access.some(item => item.node_access === "0.1.12") && (role === 'hospital_bdo' || role === 'hospital_pro')) && (
                <div className="home-tiles" onClick={() => navigate("/payment")}>
                    <img src={payment} className="home-icon" width="65px" alt="Payment" />
                    <h6 className="each-title">Payment</h6>
                </div>
            )}
            {(access.some(item => ["0.1.13", "0.1.13.2"].includes(item.node_access)) && (role === 'hospital_bdo' || role === 'hospital_pro')) && (
                <div className="home-tiles" onClick={() => navigate("/service_request")}>
                    <img src={request} className="home-icon" width="65px" alt="Service Fulfillment" />
                    <h5 className="each-title">Service Fulfillment</h5>
                </div>
            )}
            {(access.some(item => ["0.1.6.2", "0.1.6"].includes(item.node_access)) && (role === 'hospital_bdo' || role === 'hospital_pro')) && (
                <div className="home-tiles" onClick={() => navigate("/personal-inventory")}>
                    <img src={inventory} className="home-icon" width="65px" alt="Personal Inventory" />
                    <h6 className="each-title">Personal Inventory</h6>
                </div>
            )}
            {(access.some(item => item.node_access === "0.1.9") && (role === 'hospital_bdo' || role === 'hospital_pro')) && (
                <div className="home-tiles" onClick={() => navigate("/volunteers")}>
                    <img src={volunteers} className="home-icon" width="65px" alt="Volunteers" />
                    <h5 className="each-title">Volunteers</h5>
                </div>
            )}
            {(access.some(item => ["0.1.1", "0.1.1.1", "0.1.1.2", "0.1.1.3", "0.1.1.4"].includes(item.node_access)) && (role === 'hospital_bdo' || role === 'hospital_pro' || role === 'hospital_front_office')) && (
                <div className="home-tiles" onClick={() => navigate("/dialysis")}>
                    <img src={dialysis} className="home-icon" width="65px" alt="Dialysis" />
                    <h5 className="each-title">Dialysis</h5>
                </div>
            )}
            {(access.some(item => ["0.1.3", "0.1.3.1", "0.1.3.2", "0.1.3.3"].includes(item.node_access)) && (role === 'hospital_bdo' || role === 'hospital_pro' || role === 'hospital_front_office')) && (
                <div className="home-tiles" onClick={() => navigate("/Phys")}>
                    <div className="home-icon" style={{ display: 'flex', alignItems: 'center' }} width="65px">
                        <PhysioSvg />
                    </div>
                    <h5 className="each-title">Physiotherapy Unit</h5>
                </div>
            )}
            {(access.some(item => ["0.1.2", "0.1.2.1", "0.1.2.2", "0.1.2.3"].includes(item.node_access)) && (role === 'hospital_bdo' || role === 'hospital_pro' || role === 'hospital_front_office')) && (
                <div className="home-tiles" onClick={() => navigate("/palliative")}>
                    <div className="home-icon" style={{ display: 'flex', alignItems: 'center' }} width="65px">
                        <PalliativeSvg />
                    </div>
                    <h5 className="each-title">Palliative Care</h5>
                </div>
            )}
            {(access.some(item => item.node_access === "0.1.8") && (role === 'hospital_bdo' || role === 'hospital_pro')) && (
                <div className="home-tiles" onClick={() => navigate("/selected-services")}>
                    <img src={sda} className="home-icon" width="65px" alt="Services" />
                    <h5 className="each-title">Services</h5>
                </div>
            )}
            {(access.some(item => item.node_access === "0.1.10") && (role === 'hospital_doctor')) && (
                <div className="home-tiles" onClick={() => navigate("/doctor_schedule")}>
                    <img src={calendar} className="home-icon" width="65px" />
                    <h5 className="each-title">Schedule</h5>
                </div>
            )}
             {(access.some(item => item.node_access === "0.1.8") && (role === 'hospital_bdo' || role === 'hospital_pro')) && (
                <div className="home-tiles" onClick={() => navigate("/discharge")}>
                    <img src={discharge} className="home-icon" width="65px" />
                    <h5 className="each-title">Discharge</h5>
                </div>
            )} 
            {(access.some(item => ["0.1.4", "0.1.4.1"].includes(item.node_access)) && (role === 'hospital_doctor' || role === 'hospital_nurse')) && (
                <div className="home-tiles" onClick={() => navigate("/wound_chart")}>
                    <img src={wound} className="home-icon" width="65px" />
                    <h5 className="each-title">Wound Documentation</h5>
                </div>
            )}            
        </div>
    );
};
export default RenderTiles