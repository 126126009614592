import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import bed from "../../assets/images/icons/bed.png";
import ward from "../../assets/images/icons/ward.png";
import { APIURL } from "../../Global";
import { useAlert } from "react-alert";
import axios from "axios";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { NavDropdown } from "react-bootstrap";

function AssignPatient(props) {
  const navigate = useNavigate();
  const alert = useAlert();
  const location = useLocation();
  const [patientDetails, setPatientDetails] = useState({
    id: (location.state && location.state.id) ? location.state.id : props.from === "admission" ? props.id : "",
    name: location.state ? location.state.name : "",
    gender: location.state ? location.state.gender : "",
    age: location.state ? location.state.age : "",
    mob: location.state ? location.state.mob : "",
  });
  const [DocDetails, setDocDetails] = useState({});
  const [fromPR, setFrmPr] = useState(location.state ? true : false);
  const [refresh, setRefresh] = useState(false);
  const [refresh1, setRefresh1] = useState(false);
  const [DutyDocDetails, setDutyDocDetails] = useState([]);
  const [NurseDetails, setNurseDetails] = useState([]);
  const [CarerDetails, setCarerDetails] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [deluxe, setDeluxe] = useState([]);
  const [semi, setSemi] = useState([]);
  const [executive, setExecutive] = useState([]);
  const [vip, setVip] = useState([]);
  const [vvip, setVVip] = useState([]);
  const [std, setStd] = useState([]);
  const [patientDetails1, setPatientDetails1] = useState([]);
  const [DocDetails1, setDocDetails1] = useState([]);
  const [DutyDocDetails1, setDutyDocDetails1] = useState([]);
  const [NurseDetails1, setNurseDetails1] = useState([]);
  const [CarerDetails1, setCarerDetails1] = useState([]);
  const [buildings1, setBuildings1] = useState([]);

  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [roomSelect, setRoomSelect] = useState(null);
  const [floorSelect, setFloorSelect] = useState(null);
  const [floorwardselect, setFloorWardSelect] = useState(null);
  const [wardSelect, setWardSelect] = useState(null);
  const [wardSelect1, setWardSelect1] = useState(null);
  const [buildingName, setBuildingName] = useState(null);
  const ref1 = useRef(null);
  const name = location.state ? location.state.name : "";
  const id = location.state ? location.state.id : "";
  const [expand, setExpand] = useState();
  const [floors, setFloors] = useState([]);
  const [wards, setWards] = useState([]);
  const [roomData, setRoomData] = useState([]);
  const [wardData, setWardData] = useState([]);
  const [wardData1, setWardData1] = useState([]);
  const [bedavailability, setbedAvailibitiy] = useState([]);
  const [show, setShow] = useState(false);
  const [assigned, setAssigned] = useState(false)
  const [roomClick, setRoomClick] = useState(false);
  const [wardClick, setWardClick] = useState(false);
  const [roomAvailableShow, setRoomAvailableShow] = useState(false);
  const [wardAvailableShow, setWardAvailableShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchPat, setSearchPat] = useState(false);
  const [searchDoc, setSearchDoc] = useState(false);
  const [searchDDoc, setSearchDDoc] = useState(false);
  const [searchNur, setSearchNur] = useState(false);
  const [searchCare, setSearchCare] = useState(false);
  const [displayPat, setDisplayPat] = useState(false);
  const [bedUnitCode, setBedUnitCode] = useState("");
  const [wardType, setWardType] = useState("");
  const [price, setPrice] = useState("");
  const handleOpen = () => {
    setShow(!show);
  };
  const [patient, setPatient] = useState([]);
  const handleClickOutside = (event) => {
    if (ref1.current && !ref1.current.contains(event.target)) {
      setShow(false);
    }
  };

  const handleDelete = (e, id) => {

    e.preventDefault();
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    const dataToSend = {
      duty_doctor: id,
      bedunit_code: bedUnitCode
    }
    axios
      .put(`${APIURL}/api/v1/service-provider/assign-room/`, dataToSend, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          alert.success(res.data.message)

          setRefresh(!refresh)
        }
      });
  }
  const handleDelete1 = (e, id) => {


    e.preventDefault();
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    const dataToSend = {
      duty_nurse: id,
      bedunit_code: bedUnitCode
    }
    axios
      .put(`${APIURL}/api/v1/service-provider/assign-room/`, dataToSend, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          alert.success(res.data.message)

          setRefresh(!refresh)
        }
      });
  }
  const handleDelete2 = (e, id) => {

    e.preventDefault();
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    const dataToSend = {
      duty_carer: id,
      bedunit_code: bedUnitCode
    }
    axios
      .put(`${APIURL}/api/v1/service-provider/assign-room/`, dataToSend, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          alert.success(res.data.message)
          setRefresh(!refresh)
        }
      });
  }
  useEffect(() => {
    setDeluxe(
      roomData.filter((item) => {
        return item.room_type === "deluxe";
      })
    );
    setSemi(
      roomData.filter((item) => {
        return item.room_type === "semi-deluxe";
      })
    );
    setExecutive(
      roomData.filter((item) => {
        return item.room_type === "executive";
      })
    );
    setVip(
      roomData.filter((item) => {
        return item.room_type === "vip";
      })
    );
    setVVip(
      roomData.filter((item) => {
        return item.room_type === "vvip";
      })
    );
    setStd(
      roomData.filter((item) => {
        return item.room_type === "standard";
      })
    );
  }, [roomData]);


  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    const apiUrl = `${APIURL}/api/v1/service-provider/building/` +
      (props.dep !== undefined && props.dep !== null && props.dep !== ''
        ? `?department_id=${props.dep}`
        : '');

    axios
      .get(apiUrl, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setBuildings(res.data);

        }
      });
  }, []);
  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(
        `${APIURL}/api/v1/service-provider/assign-room/?bedunit_code=${bedUnitCode}`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setDocDetails1(res.data.data.chief_doctor);
          setPatientDetails1(res.data.data.patient);
          setDutyDocDetails1(res.data.data.duty_doctor);
          setCarerDetails1(res.data.data.carer);
          setNurseDetails1(res.data.data.nurse);
        }
      });
  }, [bedUnitCode, refresh, wardSelect]);

  const handleClick = (room) => {
    setRoomSelect(room);
  };

  const handleClickWard = (room) => {

    setWardSelect(room);
    setSearchPat("");
    setSearchDDoc("");
    setSearchDoc("");
    setSearchCare("");
    setSearchNur("");
    setSearchTerm("");
  };

  // useEffect(() => {

  // }, [refresh1]);

  const handleFloorWardClick = (floor, index) => {
    setBedUnitCode("");
    setFloorWardSelect(floor);
    if (expand !== index) {
      setExpand(index);
      handleFloorWardClick1(floor);
    } else {
      setExpand();
    }
  };
  const initialRender = useRef(true);

  useEffect(() => {
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (initialRender.current) {
      initialRender.current = false;
    } else if (searchTerm === "") {
      setPatient([]);
    } else {
      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */
      let url = "";
      if (searchPat) {
        url =
          "/api/v1/staff/patient-list/?timeZone=" + currentTimezone + "&per_page=1000&page=1&op=true&q=" + searchTerm;
      } else if (searchDoc || searchDDoc) {
        url = "/api/v1/connect/doctorslist/?timeZone=" + currentTimezone + "&q=" + searchTerm;
      } else if (searchNur) {
        url = "/api/v1/service-provider/nurse/?timeZone=" + currentTimezone + "&assignee&search=" + searchTerm;
      } else {
        url = "/api/v1/service-provider/carers/?timeZone=" + currentTimezone + "&assignee&search=" + searchTerm;
      }

      axios
        .get(`${APIURL}${url}`, {
          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (searchPat) {
              setPatient(res.data.patient);
            } else if (searchDoc || searchDDoc) {
              setPatient(res.data.results);
            }

            // setServiceShow(false)
            setDisplayPat(true);
          } else if (res.status === 200) {
            setPatient(res.data);
            setDisplayPat(true);
          }
        })
        .catch((error) => { });
    }
  }, [searchTerm]);

  const handleSearchChangeMsg = (e) => {
    setSearchTerm(e.target.value);
    setDisplayPat(false);
  };
  const handleWardsData = (data, ward1, type, price) => {
    setWardType(type);
    setPrice(price);
    setWardSelect1(ward1);
    setWardAvailableShow(true);
    setWardData1(data);
    setWardSelect(null);

  };
  const handleFloorWardClick1 = (floor) => {
    setWardAvailableShow(false);
    setWardSelect(null);
    const tokenString = sessionStorage.getItem("usertoken");
    const v = tokenString;
    let str = v.replace(/["]+/g, "");

    const apiUrl = `${APIURL}/api/v1/service-provider/room-availability/?ward=True&floor=${floor}` +
      (props.dep !== undefined && props.dep !== null && props.dep !== ''
        ? `&department_id=${props.dep}`
        : '');

    axios
      .get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setWardData(res.data.ward_data);


        }
      });
  };

  useEffect(() => {
    if (wardAvailableShow) {
      handleFloorWardClick1(floorwardselect);
    }
    else {
      handleFloorClick(floorSelect);
    }
  }, [refresh1]);

  const handleFloorClick = (floor) => {
    setBedUnitCode("");
    setFloorSelect(floor);
    setRoomSelect(null);
    const tokenString = sessionStorage.getItem("usertoken").replace(/["]+/g, "");

    const apiUrl = `${APIURL}/api/v1/service-provider/room-availability/?rooms=True&floor=${floor}` +
      (props.dep !== undefined && props.dep !== null && props.dep !== ''
        ? `&department_id=${props.dep}`
        : '');

    axios
      .get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + tokenString,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setRoomData(res.data.room_data);
          setRoomAvailableShow(true);
        }
      });
  };
  const handleClickSearch = (item) => {
    initialRender.current = true;
    setSearchTerm(item.full_name);
    setSearchTerm("");
    setDisplayPat(false);
    setSearchPat(false);
    if (searchPat) {
      setPatientDetails({ id: item.id, name: item.full_name });
    } else if (searchDoc) {
      setDocDetails({ id: item.id, name: item.full_name });
    } else if (searchDDoc) {
      let arr = [];
      DutyDocDetails.map((item) => {
        arr.push(item.id);
      });
      if (!arr.includes(item.id) && !DutyDocDetails1.includes(item.full_name)) {
        setDutyDocDetails([
          ...DutyDocDetails,
          { id: item.id, name: item.full_name },
        ]);
      }
    } else if (searchNur) {
      let arr = [];
      NurseDetails.map((item) => {
        arr.push(item.id);
      });
      if (!arr.includes(item.user) && !NurseDetails1.includes(item.name)) {
        setNurseDetails([...NurseDetails, { id: item.user, name: item.name }]);
      }
    } else {
      let arr = [];
      CarerDetails.map((item) => {
        arr.push(item.id);
      });
      if (!arr.includes(item.user) && !CarerDetails1.includes(item.name)) {
        setCarerDetails([...CarerDetails, { id: item.user, name: item.name }]);
      }
    }
  };
  const Search = (term) => {

    return (
      <>
        <div className="flex-row1">
          <div className="search-form" style={{ width: "100%" }}>
            <input
              className="search-input"
              type="text"
              maxLength="100"
              style={{ width: "100%" }}
              placeholder={"Search " + term}
              value={searchTerm}
              onChange={handleSearchChangeMsg}
            />

            <button
              // onClick={handleSearch}
              className="btn btn-primary search-icon"
            >
              {" "}
              <i className="fa fa-search"></i>{" "}
            </button>
          </div>
        </div>
        {displayPat ? (
          <div
            className="suggest-display adjust-dimensions"
            style={{ width: "87%" }}
          >
            {patient.length > 0 ? (
              patient.map((item) => {
                return (
                  <p
                    onClick={() => handleClickSearch(item)}
                    className="suggest-item text-secondary"
                  >
                    {searchNur || searchCare ? item.name : item.full_name}
                  </p>
                );
              })
            ) : (
              <h6 className="text-muted">No User found !!</h6>
            )}
          </div>
        ) : null}
      </>
    );
  };
  const Submit = () => {
    if (!roomClick && !wardClick) {
      alert.error("Select a room or ward");
    } else if (bedUnitCode === "") {
      alert.error("Select a bed");
    } else if (
      (patientDetails.id !== "" && patientDetails1.length === 0) ||
      DocDetails.id !== "" ||
      NurseDetails.length > 0 ||
      DutyDocDetails.length > 0 ||
      CarerDetails.length > 0
    ) {
      const tokenString = sessionStorage.getItem("usertoken");
      const v = tokenString;
      let str = v.replace(/["]+/g, "");
      let dataToSend = {
        bedunit_code: bedUnitCode,
        bedspace_type: roomClick ? "room" : "ward",
        department: props.dep ? props.dep : 1,
        // ...(props.dep !== '' && { department: props.dep }),
        ...(props.admit_request_id !== '' && { admit_request_id: props.request_id })
      };

      if (patientDetails1.length === 0) {
        if (patientDetails.id) dataToSend["patient"] = patientDetails.id;
      }
      if (DocDetails.id) dataToSend["chief_doctor"] = DocDetails.id;

      if (DutyDocDetails.length > 0) {
        let arr = [];
        DutyDocDetails.map((item) => {
          arr.push(item.id);
        });
        dataToSend["duty_doctor"] = arr;
      }
      if (NurseDetails.length > 0) {
        let arr = [];
        NurseDetails.map((item) => {
          arr.push(item.id);
        });
        dataToSend["duty_nurse"] = arr;
      }
      if (CarerDetails.length > 0) {
        let arr = [];
        CarerDetails.map((item) => {
          arr.push(item.id);
        });
        dataToSend["duty_carer"] = arr;
      }

      axios
        .post(`${APIURL}/api/v1/service-provider/assign-room/`, dataToSend, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + tokenString.replace(/["]+/g, ""),
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (props.from) {
              props.onSuccess(bedUnitCode)
            }
            setCarerDetails([]);
            setDocDetails({ name: "", id: "" });
            setNurseDetails([]);
            setDutyDocDetails([]);
            setPatientDetails({ name: "", id: "" });
            alert.success("Successfully Assigned");

            setRefresh(!refresh);
            setRefresh1(!refresh1);
            // setRoomAvailableShow(false);
            //setWardAvailableShow(false)
          }
          else{
            alert.error(res.data.message)
          }
        });
    }
    else { alert.error("Room is already occupied") }
  };
  const Update = () => {
    if (!roomClick && !wardClick) {
      alert.error("Select a room or ward");
    } else if (bedUnitCode === "") {
      alert.error("Select a bed");
    }
    else if (assigned) {
      alert.error("Already occupied")
    }
    else if (
      (patientDetails.id !== "")

    ) {
      const tokenString = sessionStorage.getItem("usertoken");
      const v = tokenString;
      let str = v.replace(/["]+/g, "");
      let dataToSend = {
        bedunit_code: bedUnitCode,
        bedspace_type: roomClick ? "room" : "ward",
        patient: patientDetails.id,
        ...(props.dep !== '' && { department: props.dep }),
        ...(props.admit_id !== '' && { admit_id: props.admit_id }),
        ...(props.request_id !== '' && { admit_request_id: props.request_id })
      };


      axios
        .put(`${APIURL}/api/v1/service-provider/assign-room/`, dataToSend, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + tokenString.replace(/["]+/g, ""),
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (props.from) {
              alert.success("Successfully reassigned");
              props.onSuccess(bedUnitCode)
            }
            else {
              alert.success("Successfully reassigned");
              navigate(`/patient-record/id/${patientDetails.id}/mob/${patientDetails.mobr}`)

            }

          }
          else{alert.error(res.data.message)}
        });
    }

  };
  const handleRoomClick = () => {

    setRoomSelect(null);
    setRoomAvailableShow(false);
    setWardAvailableShow(false);
    setWardSelect(null);
    setRoomClick(true);
    setWardClick(false);
    const tokenString = sessionStorage.getItem("usertoken").replace(/["]+/g, "");
    const apiUrl = `${APIURL}/api/v1/service-provider/room-availability/?rooms=True&building=${selectedBuilding}` +
      (props.dep !== undefined && props.dep !== null && props.dep !== ''
        ? `&department_id=${props.dep}`
        : '');

    axios
      .get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + tokenString,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setFloors(res.data.floor_data);
        }
      });
  };

  const handleWardClick = (e) => {
    e.preventDefault();

    setWardClick(true);
    setRoomClick(false);
    setRoomAvailableShow(false);
    setRoomSelect(null);
    setBedUnitCode("");
    setWardAvailableShow(false);
    setWardSelect(null);
    const tokenString = sessionStorage.getItem("usertoken").replace(/["]+/g, "");
    const apiUrl = `${APIURL}/api/v1/service-provider/room-availability/?ward=True&building=${selectedBuilding}` +
      (props.dep !== undefined && props.dep !== null && props.dep !== ''
        ? `&department_id=${props.dep}`
        : '');

    axios
      .get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + tokenString,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setWards(res.data.floor_data);
        }
      });
  };

  const handleBuildingClick = (building, build_name) => {
    setBedUnitCode("");
    setWardClick(false);
    setRoomClick(false);
    setFloors([]);
    setWards([])
    setRoomAvailableShow(false);
    setRoomSelect(null);
    setWardSelect(null);
    setBuildingName(build_name);

    setSelectedBuilding(building);
    setWardAvailableShow(false);
    const tokenString = sessionStorage.getItem("usertoken").replace(/["]+/g, "");
    const apiUrl = `${APIURL}/api/v1/service-provider/floor/?building=${building}` +
      (props.dep !== undefined && props.dep !== null && props.dep !== ''
        ? `&department_id=${props.dep}`
        : '');


    axios
      .get(apiUrl, {
        headers: {
          Authorization: "Token " + tokenString,
        },
      })
      .then((res) => {
        if (res.status === 200) {

        }
      });

  };

  return (
    <>
      <div className="facilityouter container">
        {/* Showing building names here */}

        <div className="row">
          {buildings.map(
            (building, index) =>
              index < 5 && (
                <button
                  className="col-1"
                  key={building.id}
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '100%',
                    width: "140.145px",
                    height: "38px",
                    flexShrink: "0",
                    borderRadius: "8px",
                    border:
                      selectedBuilding === building.id
                        ? "2px solid #034AFF"
                        : "1px solid #6B6F7B",
                    color:
                      selectedBuilding === building.id ? "black" : "#6B6F7B",
                    backgroundColor:
                      selectedBuilding === building.id ? "white" : "",

                    marginTop: "3%",
                    marginRight: "5px",
                    marginLeft: "3%",
                    fontFamily: "Roboto",
                    fontSize: "13px",
                  }}
                  onClick={() => {
                    setCarerDetails([]);
                    setDocDetails({ name: "", id: "" });
                    setNurseDetails([]);
                    setDutyDocDetails([]);
                    setFloors([]);
                    setWards([])
                    setWardData([])
                    setCarerDetails1([]);
                    setDocDetails1([]);
                    setNurseDetails1([]);
                    setDutyDocDetails1([]);
                    setPatientDetails1([]);
                    handleBuildingClick(building.id, building.build_name);
                  }}
                >
                  {" "}
                  {selectedBuilding === building.id ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="18"
                      viewBox="0 0 12 18"
                      fill="none"
                    >
                      <path
                        d="M1.12158 1V17H10.1997V4.06667L1.12158 1Z"
                        fill="#1443BB"
                        stroke="#034AFF"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <rect
                        x="2.34839"
                        y="4.75"
                        width="2.69889"
                        height="3"
                        rx="0.5"
                        stroke="#034AFF"
                        stroke-width="2"
                      />
                      <rect
                        x="2.34839"
                        y="8.75"
                        width="2.69889"
                        height="3"
                        rx="0.5"
                        stroke="#034AFF"
                        stroke-width="2"
                      />
                      <rect
                        x="2.34839"
                        y="12.75"
                        width="2.69889"
                        height="3"
                        rx="0.5"
                        stroke="#034AFF"
                        stroke-width="2"
                      />
                      <rect
                        x="6.27393"
                        y="4.75"
                        width="2.69889"
                        height="3"
                        rx="0.5"
                        stroke="#034AFF"
                        stroke-width="2"
                      />
                      <rect
                        x="6.27393"
                        y="8.75"
                        width="2.69889"
                        height="3"
                        rx="0.5"
                        stroke="#034AFF"
                        stroke-width="2"
                      />
                      <rect
                        x="6.27393"
                        y="12.75"
                        width="2.69889"
                        height="3"
                        rx="0.5"
                        stroke="#034AFF"
                        stroke-width="2"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11"
                      height="18"
                      viewBox="0 0 11 18"
                      fill="none"
                    >
                      <path
                        d="M1.12158 1V17H10.1997V4.06667L1.12158 1Z"
                        stroke="#6B6F7B"
                        stroke-width="0.8"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <rect
                        x="2.59839"
                        y="5"
                        width="2.19889"
                        height="2.5"
                        rx="0.25"
                        stroke="#6B6F7B"
                        stroke-width="0.5"
                      />
                      <rect
                        x="2.59839"
                        y="9"
                        width="2.19889"
                        height="2.5"
                        rx="0.25"
                        stroke="#6B6F7B"
                        stroke-width="0.5"
                      />
                      <rect
                        x="2.59839"
                        y="13"
                        width="2.19889"
                        height="2.5"
                        rx="0.25"
                        stroke="#6B6F7B"
                        stroke-width="0.5"
                      />
                      <rect
                        x="6.52393"
                        y="5"
                        width="2.19889"
                        height="2.5"
                        rx="0.25"
                        stroke="#6B6F7B"
                        stroke-width="0.5"
                      />
                      <rect
                        x="6.52393"
                        y="9"
                        width="2.19889"
                        height="2.5"
                        rx="0.25"
                        stroke="#6B6F7B"
                        stroke-width="0.5"
                      />
                      <rect
                        x="6.52393"
                        y="13"
                        width="2.19889"
                        height="2.5"
                        rx="0.25"
                        stroke="#6B6F7B"
                        stroke-width="0.5"
                      />
                    </svg>
                  )}
                  <span>&nbsp;&nbsp;</span>
                  <b style={{ textTransform: "capitalize" }}>{building.build_number}-{building.build_name}</b>
                  <br />
                </button>
              )
          )}
          {buildings.length > 5 && (
            <NavDropdown
              className="col-1"
              ref={ref1}
              style={{ zIndex: "1002" }}
              title={
                <button
                  style={{
                    width: "133.145px",
                    height: "38px",
                    flexShrink: "0",
                    borderRadius: "8px",
                    border: "2px solid #034AFF",
                    color: "#1443BB",
                    backgroundColor: "white",
                    border: "5px",
                    marginTop: "40%",
                    marginRight: "5px",
                    marginLeft: "3%",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                  }}
                >
                  <i
                    class="fa fa-angle-double-right fa-lg"
                    aria-hidden="true"
                  ></i>
                </button>
              }
              id="collasible-nav-dropdown"
              renderMenuOnMount={true}
              onClick={handleOpen}
              show={show}
            >
              <NavDropdown.Item
                style={{
                  overflow: "scroll",
                  overflowX: "hidden",
                  overflowY: "auto",

                  maxHeight: "350px",
                  width: "328px",
                  color: "var(--green-lite, #F4FFF3)",
                  backgroundColor: "#6B6F7B",
                }}
              >
                {buildings.map(
                  (building, index) =>
                    index > 4 && (
                      <div
                        className="dropBuilding"
                        key={building.id}
                        style={{
                          height: "60px",

                          fontSize: "16px",
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          marginTop: "12px",

                          border: "none",

                          marginRight: "5px",
                          marginLeft: "3%",
                        }}
                        onClick={() => {
                          setCarerDetails([]);
                          setDocDetails({ name: "", id: "" });
                          setNurseDetails([]);
                          setDutyDocDetails([]);
                          setFloors([]);
                          setWards([])
                          setWardData([])
                          setCarerDetails1([]);
                          setDocDetails1([]);
                          setNurseDetails1([]);
                          setDutyDocDetails1([]);
                          setPatientDetails1([]);
                          handleBuildingClick(building.id, building.build_name)
                        }}
                      ><p style={{ textTransform: "capitalize" }}>
                          {building.build_number}-{building.build_name}</p>
                        <br />
                      </div>
                    )
                )}
              </NavDropdown.Item>
            </NavDropdown>
          )}
        </div>
        <br />
        <span style={{ marginLeft: "2%", marginTop: "3%", color: "black" }}>
          {" "}
          <b style={{ textTransform: "capitalize" }}>{buildingName} </b>{" "}
          {buildingName ? (
            <i class="fa fa-angle-double-right fa-lg" aria-hidden="true"></i>
          ) : (
            ""
          )}
        </span>
        {/* end of building names show */}
        {/* ############################################################################################# */}
        {/* Room and Ward toggle button  */}

        {selectedBuilding !== null && (
          <div className="row">
            <div className="col-1">
              <button
                style={{
                  width: "69px",
                  height: "44px",
                  flexShrink: "0",
                  borderRadius: "10px",
                  marginTop: "20px",
                  marginLeft: "20px",
                  border: "1px solid #A0A0A0",
                  background: roomClick ? "#098D85" : "white",
                }}
                onClick={() => handleRoomClick()}
              >
                {" "}
                <img
                  src={bed}
                  alt="Ward"
                  style={{ width: "70%", marginTop: "-12%" }}
                />
              </button>
            </div>
            <div className="col-1">
              <button
                style={{
                  width: "69px",
                  height: "44px",
                  flexShrink: "0",
                  borderRadius: "10px",
                  marginTop: "20px",
                  marginLeft: "-15px",
                  border: "1px solid #A0A0A0",
                  background: wardClick ? "#098D85" : "white",
                }}
                onClick={(e) => handleWardClick(e)}
              >
                {" "}
                <img
                  src={ward}
                  alt="Ward"
                  style={{ width: "70%", marginTop: "-12%" }}
                />
              </button>
            </div>
            <div className="col-10">
              {patientDetails.id ||
                DocDetails.id ||
                NurseDetails.length > 0 ||
                DutyDocDetails.length > 0 ||
                CarerDetails.length > 0
                ? bedUnitCode !== "" && (
                  <button
                    style={{
                      width: "69px",
                      height: "44px",
                      flexShrink: "0",
                      borderRadius: "10px",
                      float: "right",
                      marginRight: "2%",
                      marginTop: "2%",
                      border: "1px solid #A0A0A0",
                      background: "#098D85",
                      color: "white",
                    }}
                    onClick={() => { (props.reassign && props.reassign === true) || (location.state && location.state.reassign) ? Update() : Submit() }}
                  >
                    {" "}
                    {(props.reassign && props.reassign === true) || (location.state && location.state.reassign) ? "Reassign" : "Save"}
                    {" "}


                  </button>
                )
                : ""}
            </div>
          </div>
        )}

        {/* end of toggle button */}
        {/* ################################################################################################### */}
        {/* Display floor names based on room & building click */}

        <div className="row">
          <div className={props.from ? "col-5" : "col-3"}>
            {floors &&
              roomClick &&
              floors.map((floor, index) => (
                <>
                  <button
                    className="floordisplayrooms "
                    style={{
                      width: "100%",
                      marginTop: "15px",
                      marginLeft: "5px",
                      border: "1px solid #6B6F7B",
                      background:
                        floorSelect === floor.floor_id ? "#B5CA34" : "#FFF",
                    }}
                    onClick={() => handleFloorClick(floor.floor_id)}
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 1, display: "flex", justifyContent: "center", borderRight: "1px solid black" }}>
                        <div style={{ textTransform: "capitalize" }}>
                          <b> {floor.floor_number}-{floor.floor_name}</b>
                        </div>
                      </div>
                      <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
                        <span style={{ backgroundColor: "white", color: floor.available > 0 ? "green" : "red" }}>
                          <b>{floor.available > 0 ? floor.available + (floor.available === 1 ? " Room" : " Rooms") : floor.available === 0 && floor.total_room_count === 0 ? "NIL" : "FULL"}</b>
                        </span>
                      </div>
                    </div>
                    {/* {floor.floor_name} -{floor.available} Rooms Available */}
                  </button>
                </>
              ))}

            {/* Display floor names based on ward & building click */}
            {/* ################################################################################################# */}
            {wards &&
              wardClick &&
              wards.map((floor, index) => (
                <>
                  <button
                    className="floordisplayrooms"
                    style={{
                      marginTop: "15px",
                      marginLeft: "5px",
                      width: "100%",
                      background:
                        floorwardselect === floor.floor_id ? "#B5CA34" : "#FFF",
                      border: "1px solid #6B6F7B",
                    }}
                    onClick={() => handleFloorWardClick(floor.floor_id, index)}
                  ><div style={{ display: "flex" }}>
                      <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
                        <div style={{ textTransform: "capitalize" }}>
                          <b> {floor.floor_number}-{floor.floor_name}</b>
                        </div>
                      </div>
                      <div style={{ flex: 1, display: "flex", justifyContent: "center", borderLeft: "1px solid black", borderRight: "1px solid black" }}>
                        <span style={{ backgroundColor: "white", color: floor.no_of_wards > 0 ? "green" : "red" }}>
                          <b>{floor.no_of_wards > 0 ? floor.no_of_wards + (floor.no_of_wards === 1 ? " Ward" : " Wards") : "NIL"}</b>
                        </span>
                      </div>
                      <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
                        <i
                          class="fa fa-arrow-down"
                          aria-hidden="true"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                    </div>
                  </button>
                  {expand === index && wardData.length > 0
                    ? wardData.map((ward, index) => (
                      <>
                        <button
                          className="availablebox"
                          style={{ border: wardSelect1 === ward.ward_number ? "4px solid green" : "1px solid #6B6F7B" }}
                          onClick={() => {
                            setCarerDetails([]);
                            setDocDetails({ name: "", id: "" });
                            setNurseDetails([]);
                            setDutyDocDetails([]);

                            setCarerDetails1([]);
                            setDocDetails1([]);
                            setNurseDetails1([]);
                            setDutyDocDetails1([]);
                            setPatientDetails1([]);
                            handleWardsData(
                              ward.bed_availability,
                              ward.ward_number,
                              ward.ward_type,
                              ward.bed_price
                            );
                          }}
                        >
                          {" "}
                          <b style={{ textTransform: "capitalize" }}>{ward.ward_number}-{ward.ward_name} </b>
                          <span>&nbsp;&nbsp;&nbsp;</span>
                          <span style={{ backgroundColor: "white", color: ward.available > 0 ? "green" : "red" }}>
                            {ward.available > 0 ? ward.available + (ward.available === 1 ? " Bed" : " Beds") : "FULL"}</span>
                        </button>
                      </>
                    ))
                    : null}
                </>
              ))}
          </div>

          {/* Display Rooms list availability while clicking on floors */}
          {/* ############################################################################################## */}
          <div className={props.from ? "col-7" : "col-5"}>
            {/* {floors && floors.map((floor, index) => ( */}
            {roomAvailableShow ? (

              <>
                <div
                  className="displayrooms"
                  style={{
                    marginTop: "15px",
                    paddingRight: "5%",
                    paddingBottom: "1%",
                  }}
                >
                  <h5
                    style={{
                      textAlign: "inline",
                      marginTop: "30px",
                      marginLeft: "20px",
                    }}
                  >
                    <b>Rooms</b>
                  </h5>
                  <div
                    style={{
                      marginTop: "-4%",
                      marginLeft: "70%",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        width: "42px",
                        height: "14px",
                        flexShrink: "0",
                        borderRadius: "2px",
                        background: "#BEE59F",
                      }}
                    ></div>
                    <span style={{ marginLeft: "5px" }}>Available</span>
                  </div>

                  <div
                    style={{
                      marginTop: "1%",
                      marginLeft: "70%",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        width: "42px",
                        height: "14px",
                        flexShrink: "0",
                        borderRadius: "2px",
                        background: "#D69028",
                      }}
                    ></div>
                    <span style={{ marginLeft: "5px" }}>Not Available</span>
                  </div>
                  <div style={{ paddingLeft: "5%" }} className="row">
                    {deluxe.length > 0 && (
                      <>
                        <h6
                          style={{
                            textTransform: "uppercase",
                            color: "#F70D1A ",
                          }}
                        >
                          {deluxe[0].room_type}-
                          <span style={{ fontWeight: "600" }}>
                            <i class="fa fa-inr" aria-hidden="true"></i>
                            {deluxe[0].room_price}
                          </span>
                        </h6>
                        <br />
                      </>
                    )}
                    {deluxe &&
                      deluxe.map((room, index) => (
                        <div className="col-3" key={index}>
                          <button
                            style={{
                              width: "124px",
                              height: "70px",
                              flexShrink: "0",
                              borderRadius: "10px",
                              border: "1px solid #6F6F6F",
                              paddingLeft: "1%",
                              paddingRight: "1%",
                              marginBottom: "4%",
                              background: room.is_occupied
                                ? roomSelect === room.room_id
                                  ? "#7B4A00"
                                  : "#D69028"
                                : roomSelect === room.room_id
                                  ? "#295A04"
                                  : "#BEE59F",
                              color:
                                room.is_occupied || roomSelect === room.room_id
                                  ? "white"
                                  : "black",
                              cursor: room.is_occupied ? "default" : "pointer",
                            }}
                            onClick={() => {
                              setCarerDetails([]);
                              setDocDetails({ name: "", id: "" });
                              setNurseDetails([]);
                              setDutyDocDetails([]);
                              setSearchDDoc(false)
                              setSearchPat(false);
                              setSearchDoc(false);
                              setSearchNur(false);
                              setSearchCare(false);
                              setCarerDetails1([]);
                              setDocDetails1([]);
                              setNurseDetails1([]);
                              setDutyDocDetails1([]);
                              setPatientDetails1([]);
                              setBedUnitCode(room.bedunit_code);
                              handleClick(room.room_id);
                            }}
                          >
                            <div
                              style={{
                                margin: "4%",
                                flexShrink: "0",
                                borderRadius: "10px",
                                border: "1px solid #6F6F6F",
                                background: "white",
                                color:
                                  room.room_type === "deluxe"
                                    ? "#F70D1A "
                                    : room.room_type === "semi-deluxe"
                                      ? "#FF4500"
                                      : room.room_type === "executive"
                                        ? "#C24641"
                                        : room.room_type === "vvip"
                                          ? "#872657"
                                          : room.room_type === "vip"
                                            ? "blue"
                                            : "green",
                                cursor: room.is_occupied
                                  ? "default"
                                  : "pointer",
                              }}
                            >
                              Room {room.room_number}
                            </div>
                          </button>
                        </div>
                      ))}
                  </div>
                  <br />
                  <div style={{ paddingLeft: "5%" }} className="row">
                    {semi.length > 0 && (
                      <>
                        <h6
                          style={{
                            textTransform: "uppercase",
                            color: "#FF4500",
                          }}
                        >
                          {semi[0].room_type}-
                          <span style={{ fontWeight: "600" }}>
                            <i class="fa fa-inr" aria-hidden="true"></i>
                            {semi[0].room_price}
                          </span>
                        </h6>
                        <br />
                      </>
                    )}
                    {semi &&
                      semi.map((room, index) => (
                        <div className="col-3" key={index}>
                          <button
                            style={{
                              width: "124px",
                              height: "70px",
                              flexShrink: "0",
                              borderRadius: "10px",
                              border: "1px solid #6F6F6F",
                              paddingLeft: "1%",
                              paddingRight: "1%",
                              marginBottom: "4%",
                              background: room.is_occupied
                                ? roomSelect === room.room_id
                                  ? "#7B4A00"
                                  : "#D69028"
                                : roomSelect === room.room_id
                                  ? "#295A04"
                                  : "#BEE59F",
                              color:
                                room.is_occupied || roomSelect === room.room_id
                                  ? "white"
                                  : "black",
                              cursor: room.is_occupied ? "default" : "pointer",
                            }}
                            onClick={() => {
                              setCarerDetails([]);
                              setDocDetails({ name: "", id: "" });
                              setNurseDetails([]);
                              setDutyDocDetails([]);
                              setSearchDDoc(false)
                              setSearchPat(false);
                              setSearchDoc(false);
                              setSearchNur(false);
                              setSearchCare(false);
                              setCarerDetails1([]);
                              setDocDetails1([]);
                              setNurseDetails1([]);
                              setDutyDocDetails1([]);
                              setPatientDetails1([]);
                              setBedUnitCode(room.bedunit_code);
                              handleClick(room.room_id);
                            }}
                          >
                            <div
                              style={{
                                margin: "4%",
                                flexShrink: "0",
                                borderRadius: "10px",
                                border: "1px solid #6F6F6F",
                                background: "white",
                                color:
                                  room.room_type === "deluxe"
                                    ? "#F70D1A "
                                    : room.room_type === "semi-deluxe"
                                      ? "#FF4500"
                                      : room.room_type === "executive"
                                        ? "#C24641"
                                        : room.room_type === "vvip"
                                          ? "#872657"
                                          : room.room_type === "vip"
                                            ? "blue"
                                            : "green",
                                cursor: room.is_occupied
                                  ? "default"
                                  : "pointer",
                              }}
                            >
                              Room {room.room_number}
                            </div>
                          </button>
                        </div>
                      ))}
                  </div>
                  <br />
                  <div style={{ paddingLeft: "5%" }} className="row">
                    {executive.length > 0 && (
                      <>
                        <h6
                          style={{
                            textTransform: "uppercase",
                            color: "#C24641",
                          }}
                        >
                          {executive[0].room_type}-
                          <span style={{ fontWeight: "600" }}>
                            <i class="fa fa-inr" aria-hidden="true"></i>
                            {executive[0].room_price}
                          </span>
                        </h6>
                        <br />
                      </>
                    )}
                    {executive &&
                      executive.map((room, index) => (
                        <div className="col-3" key={index}>
                          <button
                            style={{
                              width: "124px",
                              height: "70px",
                              flexShrink: "0",
                              borderRadius: "10px",
                              border: "1px solid #6F6F6F",
                              paddingLeft: "1%",
                              paddingRight: "1%",
                              marginBottom: "4%",
                              background: room.is_occupied
                                ? roomSelect === room.room_id
                                  ? "#7B4A00"
                                  : "#D69028"
                                : roomSelect === room.room_id
                                  ? "#295A04"
                                  : "#BEE59F",
                              color:
                                room.is_occupied || roomSelect === room.room_id
                                  ? "white"
                                  : "black",
                              cursor: room.is_occupied ? "default" : "pointer",
                            }}
                            onClick={() => {
                              setCarerDetails([]);
                              setDocDetails({ name: "", id: "" });
                              setNurseDetails([]);
                              setDutyDocDetails([]);

                              setCarerDetails1([]);
                              setDocDetails1([]);
                              setNurseDetails1([]);
                              setDutyDocDetails1([]);
                              setPatientDetails1([]);
                              setBedUnitCode(room.bedunit_code);
                              handleClick(room.room_id);
                            }}
                          >
                            <div
                              style={{
                                margin: "4%",
                                flexShrink: "0",
                                borderRadius: "10px",
                                border: "1px solid #6F6F6F",
                                background: "white",
                                color:
                                  room.room_type === "deluxe"
                                    ? "#F70D1A "
                                    : room.room_type === "semi-deluxe"
                                      ? "#FF4500"
                                      : room.room_type === "executive"
                                        ? "#C24641"
                                        : room.room_type === "vvip"
                                          ? "#872657"
                                          : room.room_type === "vip"
                                            ? "blue"
                                            : "green",
                                cursor: room.is_occupied
                                  ? "default"
                                  : "pointer",
                              }}
                            >
                              Room {room.room_number}
                            </div>
                          </button>
                        </div>
                      ))}
                  </div>
                  <br />
                  <div style={{ paddingLeft: "5%" }} className="row">
                    {vip.length > 0 && (
                      <>
                        {" "}
                        <h6
                          style={{ textTransform: "uppercase", color: "blue " }}
                        >
                          {vip[0].room_type}-
                          <span style={{ fontWeight: "600" }}>
                            <i class="fa fa-inr" aria-hidden="true"></i>
                            {vip[0].room_price}
                          </span>
                        </h6>
                        <br />
                      </>
                    )}
                    {vip &&
                      vip.map((room, index) => (
                        <div className="col-3" key={index}>
                          <button
                            style={{
                              width: "124px",
                              height: "70px",
                              flexShrink: "0",
                              borderRadius: "10px",
                              border: "1px solid #6F6F6F",
                              paddingLeft: "1%",
                              paddingRight: "1%",
                              marginBottom: "4%",
                              background: room.is_occupied
                                ? roomSelect === room.room_id
                                  ? "#7B4A00"
                                  : "#D69028"
                                : roomSelect === room.room_id
                                  ? "#295A04"
                                  : "#BEE59F",
                              color:
                                room.is_occupied || roomSelect === room.room_id
                                  ? "white"
                                  : "black",
                              cursor: room.is_occupied ? "default" : "pointer",
                            }}
                            onClick={() => {
                              setCarerDetails([]);
                              setDocDetails({ name: "", id: "" });
                              setNurseDetails([]);
                              setDutyDocDetails([]);

                              setCarerDetails1([]);
                              setDocDetails1([]);
                              setNurseDetails1([]);
                              setDutyDocDetails1([]);
                              setPatientDetails1([]);
                              setBedUnitCode(room.bedunit_code);
                              handleClick(room.room_id);
                            }}
                          >
                            <div
                              style={{
                                margin: "4%",
                                flexShrink: "0",
                                borderRadius: "10px",
                                border: "1px solid #6F6F6F",
                                background: "white",
                                color:
                                  room.room_type === "deluxe"
                                    ? "#F70D1A "
                                    : room.room_type === "semi-deluxe"
                                      ? "#FF4500"
                                      : room.room_type === "executive"
                                        ? "#C24641"
                                        : room.room_type === "vvip"
                                          ? "#872657"
                                          : room.room_type === "vip"
                                            ? "blue"
                                            : "green",
                                cursor: room.is_occupied
                                  ? "default"
                                  : "pointer",
                              }}
                            >
                              Room {room.room_number}
                            </div>
                          </button>
                        </div>
                      ))}
                  </div>
                  <br />
                  <div style={{ paddingLeft: "5%" }} className="row">
                    {vvip.length > 0 && (
                      <>
                        {" "}
                        <h6
                          style={{
                            textTransform: "uppercase",
                            color: "#872657 ",
                          }}
                        >
                          {vvip[0].room_type}-
                          <span style={{ fontWeight: "600" }}>
                            <i class="fa fa-inr" aria-hidden="true"></i>
                            {vvip[0].room_price}
                          </span>
                        </h6>
                        <br />
                      </>
                    )}
                    {vvip &&
                      vvip.map((room, index) => (
                        <div className="col-3" key={index}>
                          <button
                            style={{
                              width: "124px",
                              height: "70px",
                              flexShrink: "0",
                              borderRadius: "10px",
                              border: "1px solid #6F6F6F",
                              paddingLeft: "1%",
                              paddingRight: "1%",
                              marginBottom: "4%",
                              background: room.is_occupied
                                ? roomSelect === room.room_id
                                  ? "#7B4A00"
                                  : "#D69028"
                                : roomSelect === room.room_id
                                  ? "#295A04"
                                  : "#BEE59F",
                              color:
                                room.is_occupied || roomSelect === room.room_id
                                  ? "white"
                                  : "black",
                              cursor: room.is_occupied ? "default" : "pointer",
                            }}
                            onClick={() => {
                              if (room.is_occupied) {
                                setAssigned(true)
                              }
                              else {
                                setAssigned(false)
                              }

                              setCarerDetails([]);
                              setDocDetails({ name: "", id: "" });
                              setNurseDetails([]);
                              setDutyDocDetails([]);

                              setCarerDetails1([]);
                              setDocDetails1([]);
                              setNurseDetails1([]);
                              setDutyDocDetails1([]);
                              setPatientDetails1([]);
                              setBedUnitCode(room.bedunit_code);
                              handleClick(room.room_id);
                            }}
                          >
                            <div
                              style={{
                                margin: "4%",
                                flexShrink: "0",
                                borderRadius: "10px",
                                border: "1px solid #6F6F6F",
                                background: "white",
                                color:
                                  room.room_type === "deluxe"
                                    ? "#F70D1A "
                                    : room.room_type === "semi-deluxe"
                                      ? "#FF4500"
                                      : room.room_type === "executive"
                                        ? "#C24641"
                                        : room.room_type === "vvip"
                                          ? "#872657"
                                          : room.room_type === "vip"
                                            ? "blue"
                                            : "green",
                                cursor: room.is_occupied
                                  ? "default"
                                  : "pointer",
                              }}
                            >
                              Room {room.room_number}
                            </div>
                          </button>
                        </div>
                      ))}
                  </div>
                  <br />
                  <div style={{ paddingLeft: "5%" }} className="row">
                    {std.length > 0 && (
                      <>
                        <h6
                          style={{ textTransform: "uppercase", color: "green" }}
                        >
                          {std[0].room_type}-
                          <span style={{ fontWeight: "600" }}>
                            <i class="fa fa-inr" aria-hidden="true"></i>
                            {std[0].room_price}
                          </span>
                        </h6>
                        <br />
                      </>
                    )}
                    {std &&
                      std.map((room, index) => (
                        <div className="col-3" key={index}>
                          <button
                            style={{
                              width: "124px",
                              height: "70px",
                              flexShrink: "0",
                              borderRadius: "10px",
                              border: "1px solid #6F6F6F",
                              paddingLeft: "1%",
                              paddingRight: "1%",
                              marginBottom: "4%",
                              background: room.is_occupied
                                ? roomSelect === room.room_id
                                  ? "#7B4A00"
                                  : "#D69028"
                                : roomSelect === room.room_id
                                  ? "#295A04"
                                  : "#BEE59F",
                              color:
                                room.is_occupied || roomSelect === room.room_id
                                  ? "white"
                                  : "black",
                              cursor: room.is_occupied ? "default" : "pointer",
                            }}
                            onClick={() => {
                              setCarerDetails([]);
                              setDocDetails({ name: "", id: "" });
                              setNurseDetails([]);
                              setDutyDocDetails([]);

                              setCarerDetails1([]);
                              setDocDetails1([]);
                              setNurseDetails1([]);
                              setDutyDocDetails1([]);
                              setPatientDetails1([]);
                              setBedUnitCode(room.bedunit_code);
                              handleClick(room.room_id);
                            }}
                          >
                            <div
                              style={{
                                margin: "4%",
                                flexShrink: "0",
                                borderRadius: "10px",
                                border: "1px solid #6F6F6F",
                                background: "white",
                                color:
                                  room.room_type === "deluxe"
                                    ? "#F70D1A "
                                    : room.room_type === "semi-deluxe"
                                      ? "#FF4500"
                                      : room.room_type === "executive"
                                        ? "#C24641"
                                        : room.room_type === "vvip"
                                          ? "#872657"
                                          : room.room_type === "vip"
                                            ? "blue"
                                            : "green",
                                cursor: room.is_occupied
                                  ? "default"
                                  : "pointer",
                              }}
                            >
                              Room {room.room_number}
                            </div>
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
              </>
            ) : wardAvailableShow ? (
              <>
                {/* ################################################################################################## */}
                {/* Display Ward Bed list availability while clicking on floors */}
                <div className="displayrooms" style={{ marginTop: "15px", paddingBottom: "2%" }}>
                  <h5
                    style={{
                      textAlign: "inline",
                      marginTop: "30px",
                      marginLeft: "20px",
                    }}
                  >
                    <b>Beds</b>
                  </h5>
                  <div
                    style={{
                      marginTop: "-4%",
                      marginLeft: "70%",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        width: "42px",
                        height: "14px",
                        flexShrink: "0",
                        borderRadius: "2px",
                        background: "#BEE59F",
                      }}
                    ></div>
                    <span style={{ marginLeft: "5px" }}>Available</span>
                  </div>

                  <div
                    style={{
                      marginTop: "1%",
                      marginLeft: "70%",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        width: "42px",
                        height: "14px",
                        flexShrink: "0",
                        borderRadius: "2px",
                        background: "#D69028",
                      }}
                    ></div>
                    <span style={{ marginLeft: "5px" }}>Not Available</span>
                  </div>
                  {wardType !== "" && (
                    <>
                      <h6
                        style={{
                          marginLeft: "50px",
                          textTransform: "uppercase",
                          color:
                            wardType === "deluxe"
                              ? "#F70D1A "
                              : wardType === "semi-deluxe"
                                ? "#FF4500"
                                : wardType === "executive"
                                  ? "#C24641"
                                  : wardType === "vvip"
                                    ? "#872657"
                                    : wardType === "vip"
                                      ? "blue"
                                      : "green",
                        }}
                      >
                        {wardType}-
                        <span style={{ fontWeight: "600" }}>
                          <i class="fa fa-inr" aria-hidden="true"></i>
                          {price}
                        </span>
                      </h6>
                    </>
                  )}
                  <SimpleBar style={{ maxHeight: "800px" }}>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(4, .5fr)",
                        gap: "10px",
                        width: "154px",
                        marginLeft: "50px",
                        marginTop: "10px",
                      }}
                    >
                      {wardData1 &&
                        wardData1.map((ward, wardIndex) => (
                          <div key={wardIndex}>
                            <button
                              style={{
                                textTransform: "capitalize",
                                width: "80px",
                                height: "50px",
                                flexShrink: "0",
                                borderRadius: "10px",
                                border: "1px solid #6F6F6F",
                                background: ward.is_occupied
                                  ? wardSelect === ward.bed_number
                                    ? "#7B4A00"
                                    : "#D69028"
                                  : wardSelect === ward.bed_number
                                    ? "#295A04"
                                    : "#BEE59F",
                                color: ward.is_occupied
                                  ? "black"
                                  : wardSelect === ward.bed_number
                                    ? "white"
                                    : "black",
                              }}
                              onClick={() => {
                                if (ward.is_occupied) {
                                  setAssigned(true)
                                }
                                else {
                                  setAssigned(false)
                                }
                                setCarerDetails([]);
                                setDocDetails({ name: "", id: "" });
                                setNurseDetails([]);
                                setDutyDocDetails([]);

                                setCarerDetails1([]);
                                setDocDetails1([]);
                                setNurseDetails1([]);
                                setDutyDocDetails1([]);
                                setPatientDetails1([]);
                                setBedUnitCode(ward.bedunit_code);
                                handleClickWard(ward.bed_number);

                              }}
                            >
                              {"bed" + (wardIndex + 1)}
                            </button>
                          </div>
                        ))}
                    </div>
                  </SimpleBar>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          {/* ####################################################################################################### */}
          {/* Select and display Doctor and Patient,carer, nurse  */}
          {!props.from && <div className="col-4">
            {(roomSelect || wardSelect) !== null && (
              <SimpleBar style={{ maxHeight: "965px" }}>
                <div className="assignselect">
                  <div className="patientbox">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6
                        style={{
                          color: "#000",
                          fontFamily: "Poppins",
                          fontSize: "17.49px",
                          marginTop: "10px",
                          marginLeft: "5px",
                        }}
                      >
                        Patient Name
                      </h6>
                      {/* {patientDetails.id === "" &&
                        patientDetails1.length === 0 ? (
                        <button
                          onClick={() => {
                            setSearchPat(!searchPat);

                            setSearchCare(false);
                            setSearchNur(false);
                            setSearchDoc(false);

                            setSearchDDoc(false);
                            setSearchTerm("");
                            setPatient([]);
                          }}
                          style={{
                            width: "24px",
                            height: "24px",
                            flexShrink: "0",
                            marginRight: "20px",
                            marginTop: "10px",
                          }}
                        >
                          {!searchPat ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7.00696 12C7.00696 11.8011 7.08598 11.6103 7.22663 11.4697C7.36728 11.329 7.55805 11.25 7.75696 11.25H11.25V7.757C11.25 7.55809 11.329 7.36733 11.4696 7.22667C11.6103 7.08602 11.801 7.007 12 7.007C12.1989 7.007 12.3896 7.08602 12.5303 7.22667C12.6709 7.36733 12.75 7.55809 12.75 7.757V11.25H16.243C16.4419 11.25 16.6326 11.329 16.7733 11.4697C16.9139 11.6103 16.993 11.8011 16.993 12C16.993 12.1989 16.9139 12.3897 16.7733 12.5303C16.6326 12.671 16.4419 12.75 16.243 12.75H12.75V16.243C12.75 16.4419 12.6709 16.6327 12.5303 16.7733C12.3896 16.914 12.1989 16.993 12 16.993C11.801 16.993 11.6103 16.914 11.4696 16.7733C11.329 16.6327 11.25 16.4419 11.25 16.243V12.75H7.75696C7.55805 12.75 7.36728 12.671 7.22663 12.5303C7.08598 12.3897 7.00696 12.1989 7.00696 12Z"
                                fill="black"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.31696 3.769C10.4294 3.42396 13.5705 3.42396 16.683 3.769C18.51 3.973 19.985 5.412 20.199 7.249C20.569 10.406 20.569 13.595 20.199 16.752C19.984 18.589 18.509 20.027 16.683 20.232C13.5705 20.5771 10.4294 20.5771 7.31696 20.232C5.48996 20.027 4.01496 18.589 3.80096 16.752C3.43174 13.5951 3.43174 10.4059 3.80096 7.249C4.01496 5.412 5.49096 3.973 7.31696 3.769ZM16.517 5.259C13.5148 4.92624 10.4851 4.92624 7.48296 5.259C6.92721 5.32066 6.40847 5.56788 6.01054 5.96072C5.61262 6.35356 5.35876 6.86909 5.28996 7.424C4.93431 10.465 4.93431 13.537 5.28996 16.578C5.35897 17.1327 5.61292 17.648 6.01083 18.0407C6.40873 18.4333 6.92736 18.6804 7.48296 18.742C10.46 19.074 13.54 19.074 16.517 18.742C17.0724 18.6802 17.5908 18.433 17.9885 18.0404C18.3862 17.6478 18.64 17.1326 18.709 16.578C19.0646 13.537 19.0646 10.465 18.709 7.424C18.6398 6.8696 18.3859 6.35465 17.9882 5.96223C17.5905 5.5698 17.0722 5.32278 16.517 5.261V5.259Z"
                                fill="black"
                              />
                            </svg>
                          ) : (
                            <i class="fa fa-minus" aria-hidden="true"></i>
                          )}{" "}
                        </button>
                      ) : (
                        ""
                      )} */}
                    </div>
                    {searchPat && Search("patient")}

                    {patientDetails1.length > 0 ? (
                      <div className="patientdisplay">
                        <h5 style={{ marginTop: "1%" }} className="row">
                          <svg className="col-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="35"
                            height="35"
                            viewBox="0 0 35 35"
                            fill="none"
                          >
                            <circle
                              cx="17.5"
                              cy="17.5"
                              r="17.5"
                              fill="#B5CA34"
                            />
                            <text
                              x="50%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fontSize="14"
                              fontWeight="bold"
                              fill="black"
                            >
                              {patientDetails1[0].name
                                .split(" ")
                                .map((word) => word[0])
                                .join("")
                                .toUpperCase()}
                            </text>
                          </svg>
                          <span className="col-10">
                            {patientDetails1[0].name} <br />
                            <small
                              style={{

                                fontSize: "15px",
                                marginTop: "-2px",
                              }}
                            >
                              {" "}
                              {patientDetails1[0].gender} {patientDetails1[0].age}{" "}
                            </small></span>
                        </h5>
                      </div>
                    ) : patientDetails.length > 0 || patientDetails.name ? (
                      <>
                        <div className="patientdisplay-tosave">
                          <h5 style={{ marginTop: "1%" }} className="row">
                            <svg
                              className="col-2"
                              xmlns="http://www.w3.org/2000/svg"
                              width="35"
                              height="35"
                              viewBox="0 0 35 35"
                              fill="none"
                            >
                              <circle
                                cx="17.5"
                                cy="17.5"
                                r="17.5"
                                fill="#B5CA34"
                              />
                              <text
                                x="50%"
                                y="50%"
                                dominantBaseline="middle"
                                textAnchor="middle"
                                fontSize="14"
                                fontWeight="bold"
                                fill="black"
                              >
                                {patientDetails &&
                                  patientDetails.name
                                    .split(" ")
                                    .map((word) => word[0])
                                    .join("")
                                    .toUpperCase()}
                              </text>
                            </svg>
                            <span className="col-10" style={{ display: 'flex', alignItems: 'center' }}>{patientDetails.name}
                              {/* <h6
                              style={{
                               
                                fontSize: "15px",
                                marginTop: "-2px",
                              }}
                            >
                              {" "}
                              {patientDetails.gender} {patientDetails.age}{" "}
                            </h6> */}
                              {!fromPR && (
                                <button
                                  onClick={() => {
                                    setPatientDetails({ id: "", name: "" });
                                  }}
                                  style={{
                                    backgroundColor: "#F0F9FD",
                                    width: "24px",
                                    height: "24px",
                                    color: "#f8d772",
                                    flexShrink: "0",
                                    marginLeft: "auto",
                                  }}
                                >
                                  <i class="fa fa-times" aria-hidden="true"></i>{" "}
                                </button>
                              )}</span>
                          </h5>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="patientbox">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6
                        style={{
                          color: "#000",
                          fontFamily: "Poppins",
                          fontSize: "17.49px",
                          marginTop: "10px",
                          marginLeft: "5px",
                        }}
                      >
                        Chief Doctor
                      </h6>
                      {DocDetails1.length === 0 && (
                        <button
                          onClick={() => {
                            setSearchDoc(!searchDoc);
                            setSearchCare(false);
                            setSearchNur(false);

                            setSearchPat(false);
                            setSearchDDoc(false);
                            setSearchTerm("");
                            setPatient([]);
                          }}
                          style={{
                            width: "24px",
                            height: "24px",
                            flexShrink: "0",
                            marginRight: "20px",
                            marginTop: "10px",
                          }}
                        >
                          {!searchDoc ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7.00696 12C7.00696 11.8011 7.08598 11.6103 7.22663 11.4697C7.36728 11.329 7.55805 11.25 7.75696 11.25H11.25V7.757C11.25 7.55809 11.329 7.36733 11.4696 7.22667C11.6103 7.08602 11.801 7.007 12 7.007C12.1989 7.007 12.3896 7.08602 12.5303 7.22667C12.6709 7.36733 12.75 7.55809 12.75 7.757V11.25H16.243C16.4419 11.25 16.6326 11.329 16.7733 11.4697C16.9139 11.6103 16.993 11.8011 16.993 12C16.993 12.1989 16.9139 12.3897 16.7733 12.5303C16.6326 12.671 16.4419 12.75 16.243 12.75H12.75V16.243C12.75 16.4419 12.6709 16.6327 12.5303 16.7733C12.3896 16.914 12.1989 16.993 12 16.993C11.801 16.993 11.6103 16.914 11.4696 16.7733C11.329 16.6327 11.25 16.4419 11.25 16.243V12.75H7.75696C7.55805 12.75 7.36728 12.671 7.22663 12.5303C7.08598 12.3897 7.00696 12.1989 7.00696 12Z"
                                fill="black"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.31696 3.769C10.4294 3.42396 13.5705 3.42396 16.683 3.769C18.51 3.973 19.985 5.412 20.199 7.249C20.569 10.406 20.569 13.595 20.199 16.752C19.984 18.589 18.509 20.027 16.683 20.232C13.5705 20.5771 10.4294 20.5771 7.31696 20.232C5.48996 20.027 4.01496 18.589 3.80096 16.752C3.43174 13.5951 3.43174 10.4059 3.80096 7.249C4.01496 5.412 5.49096 3.973 7.31696 3.769ZM16.517 5.259C13.5148 4.92624 10.4851 4.92624 7.48296 5.259C6.92721 5.32066 6.40847 5.56788 6.01054 5.96072C5.61262 6.35356 5.35876 6.86909 5.28996 7.424C4.93431 10.465 4.93431 13.537 5.28996 16.578C5.35897 17.1327 5.61292 17.648 6.01083 18.0407C6.40873 18.4333 6.92736 18.6804 7.48296 18.742C10.46 19.074 13.54 19.074 16.517 18.742C17.0724 18.6802 17.5908 18.433 17.9885 18.0404C18.3862 17.6478 18.64 17.1326 18.709 16.578C19.0646 13.537 19.0646 10.465 18.709 7.424C18.6398 6.8696 18.3859 6.35465 17.9882 5.96223C17.5905 5.5698 17.0722 5.32278 16.517 5.261V5.259Z"
                                fill="black"
                              />
                            </svg>
                          ) : (
                            <i class="fa fa-minus" aria-hidden="true"></i>
                          )}
                        </button>
                      )}
                    </div>
                    {searchDoc && Search("doctor")}
                    {DocDetails1.length > 0 ? (
                      <div className="patientdisplay">
                        <h5 style={{ marginTop: "1%" }} className="row">
                          <svg className="col-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="35"
                            height="35"
                            viewBox="0 0 35 35"
                            fill="none"
                          >
                            <circle
                              cx="17.5"
                              cy="17.5"
                              r="17.5"
                              fill="#B5CA34"
                            />
                            <text
                              x="50%"
                              y="50%"
                              dominantBaseline="middle"
                              textAnchor="middle"
                              fontSize="14"
                              fontWeight="bold"
                              fill="black"
                            >
                              {DocDetails1[0].name
                                .split(" ")
                                .map((word) => word[0])
                                .join("")
                                .toUpperCase()}
                            </text>
                          </svg>
                          <span className="col-10">{DocDetails1[0].name} {" "}<br />
                            <small>{DocDetails1[0].specialization}</small></span>
                        </h5>
                      </div>
                    ) : DocDetails.length > 0 || DocDetails.name ? (
                      <>
                        <div className="patientdisplay-tosave">
                          <h5 style={{ marginTop: "1%" }} className="row">
                            <svg className="col-2"
                              xmlns="http://www.w3.org/2000/svg"
                              width="35"
                              height="35"
                              viewBox="0 0 35 35"
                              fill="none"
                            >
                              <circle
                                cx="17.5"
                                cy="17.5"
                                r="17.5"
                                fill="#B5CA34"
                              />
                              <text
                                x="50%"
                                y="50%"
                                dominantBaseline="middle"
                                textAnchor="middle"
                                fontSize="14"
                                fontWeight="bold"
                                fill="black"
                              >
                                {DocDetails.name
                                  .split(" ")
                                  .map((word) => word[0])
                                  .join("")
                                  .toUpperCase()}
                              </text>
                            </svg>
                            <span className="col-10" style={{ display: 'flex', alignItems: 'center' }}>{DocDetails.name}
                              <button
                                onClick={() => {
                                  setDocDetails({ id: "", name: "" });
                                }}
                                style={{
                                  backgroundColor: "#F0F9FD",
                                  width: "24px",
                                  height: "24px",
                                  color: "#f8d772",
                                  flexShrink: "0",
                                  marginLeft: "auto",
                                }}
                              >
                                <i class="fa fa-times" aria-hidden="true"></i>{" "}
                              </button></span>
                          </h5>
                        </div>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="patientbox">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6
                        style={{
                          color: "#000",
                          fontFamily: "Poppins",
                          fontSize: "17.49px",
                          marginTop: "10px",
                          marginLeft: "5px",
                        }}
                      >
                        Duty Doctors
                      </h6>
                      <button
                        onClick={() => {
                          setSearchDDoc(!searchDDoc);

                          setSearchCare(false);
                          setSearchNur(false);
                          setSearchDoc(false);
                          setSearchPat(false);
                          setSearchTerm("");
                          setPatient([]);
                        }}
                        style={{
                          width: "24px",
                          height: "24px",
                          flexShrink: "0",
                          marginRight: "20px",
                          marginTop: "10px",
                        }}
                      >
                        {!searchDDoc ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M7.00696 12C7.00696 11.8011 7.08598 11.6103 7.22663 11.4697C7.36728 11.329 7.55805 11.25 7.75696 11.25H11.25V7.757C11.25 7.55809 11.329 7.36733 11.4696 7.22667C11.6103 7.08602 11.801 7.007 12 7.007C12.1989 7.007 12.3896 7.08602 12.5303 7.22667C12.6709 7.36733 12.75 7.55809 12.75 7.757V11.25H16.243C16.4419 11.25 16.6326 11.329 16.7733 11.4697C16.9139 11.6103 16.993 11.8011 16.993 12C16.993 12.1989 16.9139 12.3897 16.7733 12.5303C16.6326 12.671 16.4419 12.75 16.243 12.75H12.75V16.243C12.75 16.4419 12.6709 16.6327 12.5303 16.7733C12.3896 16.914 12.1989 16.993 12 16.993C11.801 16.993 11.6103 16.914 11.4696 16.7733C11.329 16.6327 11.25 16.4419 11.25 16.243V12.75H7.75696C7.55805 12.75 7.36728 12.671 7.22663 12.5303C7.08598 12.3897 7.00696 12.1989 7.00696 12Z"
                              fill="black"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.31696 3.769C10.4294 3.42396 13.5705 3.42396 16.683 3.769C18.51 3.973 19.985 5.412 20.199 7.249C20.569 10.406 20.569 13.595 20.199 16.752C19.984 18.589 18.509 20.027 16.683 20.232C13.5705 20.5771 10.4294 20.5771 7.31696 20.232C5.48996 20.027 4.01496 18.589 3.80096 16.752C3.43174 13.5951 3.43174 10.4059 3.80096 7.249C4.01496 5.412 5.49096 3.973 7.31696 3.769ZM16.517 5.259C13.5148 4.92624 10.4851 4.92624 7.48296 5.259C6.92721 5.32066 6.40847 5.56788 6.01054 5.96072C5.61262 6.35356 5.35876 6.86909 5.28996 7.424C4.93431 10.465 4.93431 13.537 5.28996 16.578C5.35897 17.1327 5.61292 17.648 6.01083 18.0407C6.40873 18.4333 6.92736 18.6804 7.48296 18.742C10.46 19.074 13.54 19.074 16.517 18.742C17.0724 18.6802 17.5908 18.433 17.9885 18.0404C18.3862 17.6478 18.64 17.1326 18.709 16.578C19.0646 13.537 19.0646 10.465 18.709 7.424C18.6398 6.8696 18.3859 6.35465 17.9882 5.96223C17.5905 5.5698 17.0722 5.32278 16.517 5.261V5.259Z"
                              fill="black"
                            />
                          </svg>
                        ) : (
                          <i class="fa fa-minus" aria-hidden="true"></i>
                        )}{" "}
                      </button>
                    </div>
                    {searchDDoc && Search("Duty Doctor")}
                    {DutyDocDetails1.length > 0 && (
                      <SimpleBar style={{ maxHeight: "200px" }}>
                        {DutyDocDetails1.map((item) => {
                          return (
                            <div className="patientdisplay">
                              <h5 style={{ marginTop: "1%" }} className="row">
                                <svg className="col-2"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="35"
                                  height="35"
                                  viewBox="0 0 35 35"
                                  fill="none"
                                >
                                  <circle
                                    cx="17.5"
                                    cy="17.5"
                                    r="17.5"
                                    fill="#B5CA34"
                                  />
                                  <text
                                    x="50%"
                                    y="50%"
                                    dominantBaseline="middle"
                                    textAnchor="middle"
                                    fontSize="14"
                                    fontWeight="bold"
                                    fill="black"
                                  >
                                    {item.name
                                      .split(" ")
                                      .map((word) => word[0])
                                      .join("")
                                      .toUpperCase()}
                                  </text>
                                </svg>
                                <span className="col-10" style={{ display: 'flex', alignItems: 'center' }}>{item.name}
                                  <button

                                    style={{
                                      backgroundColor: "#F0F9FD",
                                      width: "24px",
                                      height: "24px",
                                      color: "red",
                                      flexShrink: "0",
                                      marginLeft: "auto",
                                    }}
                                  >
                                    <i
                                      class="fa fa-times"
                                      aria-hidden="true" onClick={(e) => handleDelete(e, item.id)}
                                    > </i>{" "}
                                  </button>
                                </span>
                              </h5>
                            </div>
                          );
                        })}
                      </SimpleBar>
                    )}
                    <SimpleBar style={{ maxHeight: "200px" }}>
                      {DutyDocDetails.length > 0 &&
                        DutyDocDetails.map((item) => {
                          return (
                            item.id !== "" && (
                              <div className="patientdisplay-tosave">
                                <h5
                                  style={{ marginTop: "1%" }} className="row"
                                >
                                  <svg className="col-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="35"
                                    height="35"
                                    viewBox="0 0 35 35"
                                    fill="none"
                                  >
                                    <circle
                                      cx="17.5"
                                      cy="17.5"
                                      r="17.5"
                                      fill="#B5CA34"
                                    />
                                    <text
                                      x="50%"
                                      y="50%"
                                      dominantBaseline="middle"
                                      textAnchor="middle"
                                      fontSize="14"
                                      fontWeight="bold"
                                      fill="black"
                                    >
                                      {item.name
                                        ? item.name
                                          .split(" ")
                                          .map((word) => word[0])
                                          .join("")
                                          .toUpperCase()
                                        : ""}
                                    </text>
                                  </svg>
                                  <span className="col-10" style={{ display: 'flex', alignItems: 'center' }}>{item.name}
                                    <button
                                      onClick={() => {
                                        setDutyDocDetails((current) =>
                                          current.filter((i) => i.id !== item.id)
                                        );
                                      }}
                                      style={{
                                        backgroundColor: "#F0F9FD",
                                        width: "24px",
                                        height: "24px",
                                        color: "#f8d772",
                                        flexShrink: "0",
                                        marginLeft: "auto",
                                      }}
                                    >
                                      <i
                                        class="fa fa-times"
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </button>
                                  </span>
                                </h5>
                              </div>
                            )
                          );
                        })}
                    </SimpleBar>
                  </div>

                  <div className="patientbox">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6
                        style={{
                          color: "#000",
                          fontFamily: "Poppins",
                          fontSize: "17.49px",
                          marginTop: "10px",
                          marginLeft: "5px",
                        }}
                      >
                        Duty Nurse
                      </h6>
                      <button
                        onClick={() => {
                          setSearchNur(!searchNur);
                          setSearchCare(false);

                          setSearchDoc(false);
                          setSearchPat(false);
                          setSearchDDoc(false);
                          setSearchTerm("");
                          setPatient([]);
                        }}
                        style={{
                          width: "24px",
                          height: "24px",
                          flexShrink: "0",
                          marginRight: "20px",
                          marginTop: "10px",
                        }}
                      >
                        {!searchNur ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M7.00696 12C7.00696 11.8011 7.08598 11.6103 7.22663 11.4697C7.36728 11.329 7.55805 11.25 7.75696 11.25H11.25V7.757C11.25 7.55809 11.329 7.36733 11.4696 7.22667C11.6103 7.08602 11.801 7.007 12 7.007C12.1989 7.007 12.3896 7.08602 12.5303 7.22667C12.6709 7.36733 12.75 7.55809 12.75 7.757V11.25H16.243C16.4419 11.25 16.6326 11.329 16.7733 11.4697C16.9139 11.6103 16.993 11.8011 16.993 12C16.993 12.1989 16.9139 12.3897 16.7733 12.5303C16.6326 12.671 16.4419 12.75 16.243 12.75H12.75V16.243C12.75 16.4419 12.6709 16.6327 12.5303 16.7733C12.3896 16.914 12.1989 16.993 12 16.993C11.801 16.993 11.6103 16.914 11.4696 16.7733C11.329 16.6327 11.25 16.4419 11.25 16.243V12.75H7.75696C7.55805 12.75 7.36728 12.671 7.22663 12.5303C7.08598 12.3897 7.00696 12.1989 7.00696 12Z"
                              fill="black"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.31696 3.769C10.4294 3.42396 13.5705 3.42396 16.683 3.769C18.51 3.973 19.985 5.412 20.199 7.249C20.569 10.406 20.569 13.595 20.199 16.752C19.984 18.589 18.509 20.027 16.683 20.232C13.5705 20.5771 10.4294 20.5771 7.31696 20.232C5.48996 20.027 4.01496 18.589 3.80096 16.752C3.43174 13.5951 3.43174 10.4059 3.80096 7.249C4.01496 5.412 5.49096 3.973 7.31696 3.769ZM16.517 5.259C13.5148 4.92624 10.4851 4.92624 7.48296 5.259C6.92721 5.32066 6.40847 5.56788 6.01054 5.96072C5.61262 6.35356 5.35876 6.86909 5.28996 7.424C4.93431 10.465 4.93431 13.537 5.28996 16.578C5.35897 17.1327 5.61292 17.648 6.01083 18.0407C6.40873 18.4333 6.92736 18.6804 7.48296 18.742C10.46 19.074 13.54 19.074 16.517 18.742C17.0724 18.6802 17.5908 18.433 17.9885 18.0404C18.3862 17.6478 18.64 17.1326 18.709 16.578C19.0646 13.537 19.0646 10.465 18.709 7.424C18.6398 6.8696 18.3859 6.35465 17.9882 5.96223C17.5905 5.5698 17.0722 5.32278 16.517 5.261V5.259Z"
                              fill="black"
                            />
                          </svg>
                        ) : (
                          <i class="fa fa-minus" aria-hidden="true"></i>
                        )}{" "}
                      </button>
                    </div>
                    {searchNur && Search("Nurse")}
                    {NurseDetails1.length > 0 && (
                      <SimpleBar style={{ maxHeight: "200px" }}>
                        {NurseDetails1.map((item) => {
                          return (
                            <div className="patientdisplay">
                              <h5 style={{ marginTop: "1%" }} className="row">
                                <svg className="col-2"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="35"
                                  height="35"
                                  viewBox="0 0 35 35"
                                  fill="none"
                                >
                                  <circle
                                    cx="17.5"
                                    cy="17.5"
                                    r="17.5"
                                    fill="#B5CA34"
                                  />
                                  <text
                                    x="50%"
                                    y="50%"
                                    dominantBaseline="middle"
                                    textAnchor="middle"
                                    fontSize="14"
                                    fontWeight="bold"
                                    fill="black"
                                  >
                                    {item
                                      ? item.name
                                        .split(" ")
                                        .map((word) => word[0])
                                        .join("")
                                        .toUpperCase()
                                      : ""}
                                  </text>
                                </svg>
                                <span className="col-10" style={{ display: 'flex', alignItems: 'center' }}>{item.name}   <button

                                  style={{
                                    backgroundColor: "#F0F9FD",
                                    width: "24px",
                                    height: "24px",
                                    color: "red",
                                    flexShrink: "0",
                                    marginLeft: "auto",
                                  }}
                                >
                                  <i
                                    class="fa fa-times"
                                    aria-hidden="true" onClick={(e) => handleDelete1(e, item.id)}
                                  > </i>{" "}
                                </button>
                                </span>
                              </h5>
                            </div>
                          );
                        })}
                      </SimpleBar>
                    )}
                    <SimpleBar style={{ maxHeight: "200px" }}>
                      {NurseDetails.length > 0 &&
                        NurseDetails.map((item) => {
                          return (
                            item.id !== "" && (
                              <div className="patientdisplay-tosave">
                                <h5
                                  style={{ marginTop: "1%" }}
                                  className="row"
                                >
                                  <svg className="col-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="35"
                                    height="35"
                                    viewBox="0 0 35 35"
                                    fill="none"
                                  >
                                    <circle
                                      cx="17.5"
                                      cy="17.5"
                                      r="17.5"
                                      fill="#B5CA34"
                                    />
                                    <text
                                      x="50%"
                                      y="50%"
                                      dominantBaseline="middle"
                                      textAnchor="middle"
                                      fontSize="14"
                                      fontWeight="bold"
                                      fill="black"
                                    >
                                      {item.name
                                        ? item.name
                                          .split(" ")
                                          .map((word) => word[0])
                                          .join("")
                                          .toUpperCase()
                                        : ""}
                                    </text>
                                  </svg>
                                  <span className="col-10" style={{ display: 'flex', alignItems: 'center' }}>{item.name}
                                    <button
                                      onClick={() => {
                                        setNurseDetails((current) =>
                                          current.filter((i) => i.id !== item.id)
                                        );
                                      }}
                                      style={{
                                        backgroundColor: "#F0F9FD",
                                        width: "24px",
                                        height: "24px",
                                        color: "#f8d772",
                                        flexShrink: "0",
                                        marginLeft: "auto",
                                      }}
                                    >
                                      <i
                                        class="fa fa-times"
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </button>
                                  </span>
                                </h5>
                              </div>
                            )
                          );
                        })}
                    </SimpleBar>
                  </div>
                  <div className="patientbox">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6
                        style={{
                          color: "#000",
                          fontFamily: "Poppins",
                          fontSize: "17.49px",
                          marginTop: "10px",
                          marginLeft: "5px",
                        }}
                      >
                        Carer
                      </h6>
                      <button
                        onClick={() => {
                          setSearchCare(!searchCare);

                          setSearchNur(false);
                          setSearchDoc(false);
                          setSearchPat(false);
                          setSearchDDoc(false);
                          setSearchTerm("");
                          setPatient([]);
                        }}
                        style={{
                          width: "24px",
                          height: "24px",
                          flexShrink: "0",
                          marginRight: "20px",
                          marginTop: "10px",
                        }}
                      >
                        {!searchCare ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M7.00696 12C7.00696 11.8011 7.08598 11.6103 7.22663 11.4697C7.36728 11.329 7.55805 11.25 7.75696 11.25H11.25V7.757C11.25 7.55809 11.329 7.36733 11.4696 7.22667C11.6103 7.08602 11.801 7.007 12 7.007C12.1989 7.007 12.3896 7.08602 12.5303 7.22667C12.6709 7.36733 12.75 7.55809 12.75 7.757V11.25H16.243C16.4419 11.25 16.6326 11.329 16.7733 11.4697C16.9139 11.6103 16.993 11.8011 16.993 12C16.993 12.1989 16.9139 12.3897 16.7733 12.5303C16.6326 12.671 16.4419 12.75 16.243 12.75H12.75V16.243C12.75 16.4419 12.6709 16.6327 12.5303 16.7733C12.3896 16.914 12.1989 16.993 12 16.993C11.801 16.993 11.6103 16.914 11.4696 16.7733C11.329 16.6327 11.25 16.4419 11.25 16.243V12.75H7.75696C7.55805 12.75 7.36728 12.671 7.22663 12.5303C7.08598 12.3897 7.00696 12.1989 7.00696 12Z"
                              fill="black"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.31696 3.769C10.4294 3.42396 13.5705 3.42396 16.683 3.769C18.51 3.973 19.985 5.412 20.199 7.249C20.569 10.406 20.569 13.595 20.199 16.752C19.984 18.589 18.509 20.027 16.683 20.232C13.5705 20.5771 10.4294 20.5771 7.31696 20.232C5.48996 20.027 4.01496 18.589 3.80096 16.752C3.43174 13.5951 3.43174 10.4059 3.80096 7.249C4.01496 5.412 5.49096 3.973 7.31696 3.769ZM16.517 5.259C13.5148 4.92624 10.4851 4.92624 7.48296 5.259C6.92721 5.32066 6.40847 5.56788 6.01054 5.96072C5.61262 6.35356 5.35876 6.86909 5.28996 7.424C4.93431 10.465 4.93431 13.537 5.28996 16.578C5.35897 17.1327 5.61292 17.648 6.01083 18.0407C6.40873 18.4333 6.92736 18.6804 7.48296 18.742C10.46 19.074 13.54 19.074 16.517 18.742C17.0724 18.6802 17.5908 18.433 17.9885 18.0404C18.3862 17.6478 18.64 17.1326 18.709 16.578C19.0646 13.537 19.0646 10.465 18.709 7.424C18.6398 6.8696 18.3859 6.35465 17.9882 5.96223C17.5905 5.5698 17.0722 5.32278 16.517 5.261V5.259Z"
                              fill="black"
                            />
                          </svg>
                        ) : (
                          <i class="fa fa-minus" aria-hidden="true"></i>
                        )}{" "}
                      </button>
                    </div>
                    {searchCare && Search("Carer")}
                    {CarerDetails1.length > 0 && (
                      <SimpleBar style={{ maxHeight: "200px" }}>
                        {CarerDetails1.length > 0 &&
                          CarerDetails1.map((item) => {
                            return (
                              <div className="patientdisplay">
                                <h5
                                  style={{ marginTop: "1%" }}
                                  className="row"
                                >
                                  <svg
                                    className="col-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="35"
                                    height="35"
                                    viewBox="0 0 35 35"
                                    fill="none"
                                  >
                                    <circle
                                      cx="17.5"
                                      cy="17.5"
                                      r="17.5"
                                      fill="#B5CA34"
                                    />
                                    <text
                                      x="50%"
                                      y="50%"
                                      dominantBaseline="middle"
                                      textAnchor="middle"
                                      fontSize="14"
                                      fontWeight="bold"
                                      fill="black"
                                    >
                                      {item
                                        ? item.name
                                          .split(" ")
                                          .map((word) => word[0])
                                          .join("")
                                          .toUpperCase()
                                        : ""}
                                    </text>
                                  </svg>
                                  <span className="col-10" style={{ display: 'flex', alignItems: 'center' }}>{item.name}   <button

                                    style={{
                                      backgroundColor: "#F0F9FD",
                                      width: "24px",
                                      height: "24px",
                                      color: "red",
                                      flexShrink: "0",
                                      marginLeft: "auto",
                                    }}
                                  >
                                    <i
                                      class="fa fa-times"
                                      aria-hidden="true" onClick={(e) => handleDelete2(e, item.id)}
                                    > </i>{" "}
                                  </button>
                                  </span>
                                </h5>
                              </div>
                            );
                          })}
                      </SimpleBar>
                    )}
                    <SimpleBar style={{ maxHeight: "200px" }}>
                      {CarerDetails.length > 0 &&
                        CarerDetails.map((item) => {
                          return (
                            item.id !== "" && (
                              <div className="patientdisplay-tosave">
                                <h5
                                  style={{ marginTop: "1%" }} className="row"
                                >
                                  <svg className="col-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="35"
                                    height="35"
                                    viewBox="0 0 35 35"
                                    fill="none"
                                  >
                                    <circle
                                      cx="17.5"
                                      cy="17.5"
                                      r="17.5"
                                      fill="#B5CA34"
                                    />
                                    <text
                                      x="50%"
                                      y="50%"
                                      dominantBaseline="middle"
                                      textAnchor="middle"
                                      fontSize="14"
                                      fontWeight="bold"
                                      fill="black"
                                    >
                                      {item.name
                                        ? item.name
                                          .split(" ")
                                          .map((word) => word[0])
                                          .join("")
                                          .toUpperCase()
                                        : ""}
                                    </text>
                                  </svg>
                                  <span className="col-10" style={{ display: 'flex', alignItems: 'center' }}>{item.name}
                                    <button
                                      onClick={() => {
                                        setCarerDetails((current) =>
                                          current.filter((i) => i.id !== item.id)
                                        );
                                      }}
                                      style={{
                                        backgroundColor: "#F0F9FD",
                                        width: "24px",
                                        height: "24px",
                                        color: "#f8d772",
                                        flexShrink: "0",
                                        marginLeft: "auto",
                                      }}
                                    >
                                      <i
                                        class="fa fa-times"
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </button>
                                  </span>
                                </h5>
                              </div>
                            )
                          );
                        })}
                    </SimpleBar>
                  </div>
                </div>
              </SimpleBar>
            )}
          </div>}
        </div>
      </div>
    </>
  );
}
export default AssignPatient;
