import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../../Global";
import AddPayment from "./AddPayment";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "./schedule.css";
import Reschedule from "./Reschedule";
import AddSchedule from "./AddSchedule";
import { useAlert } from 'react-alert'
import DatePicker from "react-date-picker";
import moment from 'moment'
import CancelSlot from "./CancelSlot";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import { AiOutlineMinusSquare } from "react-icons/ai";
import { MdOutlineEdit, MdDeleteOutline } from "react-icons/md";
function ScheduleList() {
  const alert = useAlert()
  const [scheduleList, setScheduleList] = useState([]);

  const [detail, setDetail] = useState({
    day_of_month: null,
    days: [],
    frequency: "",
    from_date: "",
    id: "",
    slots: [],
    to_date: "",
    week_of_month: null,
    weekday_of_month: null,
  });
  const [id, setId] = useState("");
  const [rescheduleShow, setRescheduleShow] = useState(false);
  const [addScheduleShow, setAddScheduleShow] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [paymentDone, setPaymentDone] = useState("");
  const [show, setShow] = useState(false);
  const [ToDate, setToDate] = useState("")
  const [slots, setSlots] = useState([])
  const [slotId, setSlotId] = useState("")
  const [slotCancelled, setSlotCancelled] = useState([])
  const [CancelledDates, setCancelledDates] = useState([])
  const [slotCancelledId, setSlotCancelledId] = useState([])



  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");
    let cancelledId = []
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/doctor/schedule/`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setScheduleList(res.data.schedules);
          setSlotCancelled(res.data.cancelled)
          res.data.cancelled.map((item) => {
            cancelledId.push(item.slot)
          })
          setSlotCancelledId(cancelledId)
        } else {
        }

      })
      .catch((err) => { });
  }, [refresh]);

  const handleConfirm = (id) => {
    setId(id);
    setConfirmShow(true);
  };
  const onSuccess = (data) => {

    setPaymentDone(data)
  };
  const confirmDelete = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    const data = {
      is_delete: true,
      is_confirm: true
    };

    axios
      .put(`${APIURL}/api/v1/doctor/schedule/${id}/`, data, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setConfirmShow(false);
          alert.success(res.data.message)
          setRefresh(!refresh)

        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => {
        alert.error("Error in data submission.Please try again!");
      });
  };

  const callDetail = (id) => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/doctor/schedule/${id}/`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setDetail(res.data.schedule);
          if (reschedule === true)
            setRescheduleShow(true)
        } else {
        }
      })
      .catch((err) => { });
  };
  let reschedule = false
  const handleReschedule = (id) => {
    reschedule = true
    callDetail(id);

  }


  const ReschedulePopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Reschedule detail={detail} paymentDone={paymentDone} refresh={handleRender} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const AddSchedulePopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <AddSchedule paymentDone={paymentDone} refresh={handleRefresh} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleRefresh = () => {
    setAddScheduleShow(false);
    setRefresh(!refresh);
  };

  const getCancelledDates = (id, fromDate, toDate) => {
    const filtered = slotCancelled.filter(item => {
      const cancelledDate = moment(item.date);
      const startDate = moment(fromDate);
      const endDate = moment(toDate);

      return item.slot === id && cancelledDate.isBetween(startDate, endDate, undefined, '[]');
    });
    setCancelledDates(filtered);
  };

  const handleRender = () => {
    setRescheduleShow(false);
    reschedule = false
    setRefresh(!refresh);
    // const data = sessionStorage.getItem("ID");
    // callDetail(data);
  };
  const handleSlotRefresh = () => {
    setShow(false)
    setRefresh(!refresh);
  };
  const ConfirmPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "purple" }}>Please confirm to Delete!!</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            No
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const CancelSlotPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="md"

      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Cancel Slot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CancelSlot item={slots} refresh={handleSlotRefresh} slots={slotCancelled} />

        </Modal.Body>

      </Modal>
    );
  };
  const HandleSlot = (item) => {
    const to_date = item.date.slice(25, 37)

    setToDate(moment(item.date.slice(25, 37)).format('YYYY,MM,DD'))
    setShow(true)
    setSlots(item)
  }

  const isSlotCancelled = (slotId, fromDate, toDate, cancelledData) => {
    return cancelledData.some(cancelledItem => {
      const cancelledDate = moment(cancelledItem.date);
      const startDate = moment(fromDate);
      const endDate = moment(toDate);

      return cancelledItem.slot === slotId && cancelledDate.isBetween(startDate, endDate, undefined, '[]');
    });
  };


  const display = scheduleList.map((item, index) => {
    return (
      <div className="schedule ">
        <div className="schedule-head" style={{ display: 'flex', color: '#fff', padding: '5px', justifyContent: 'space-between' }}>
          <div><i style={{ fontSize: "18px" }} class="fa fa-calendar col-7 "></i></div>
          <div className="col-4" style={{ display: 'flex', paddingLeft: 0 }}>
            <button className="new-btn-style col-4" onClick={() => handleReschedule(item.id)}><MdOutlineEdit style={{ height: '15px', width: '15px' }} /><span>Edit</span></button>
            <button className="new-btn-style col-4" style={{ padding: 0 }} onClick={() => HandleSlot(item)}><AiOutlineMinusSquare style={{ height: '15px', width: '15px' }} /><span>Cancel Slot</span></button>
            <button className="new-btn-style col-4" onClick={() => handleConfirm(item.id)}><MdDeleteOutline style={{ height: '15px', width: '15px' }} /><span>Delete</span></button>
          </div>

        </div>
        <div className="schedule-body row">
          <div className="col-4 row">
            <div className="col-5">
              <h3 className="schedule-frequancy">{item.date.slice(0, 3)}</h3>
              <small>{item.date.slice(5, 16)}</small>
            </div>
            <div className="col-2 mid-freq"><small>{item.date.slice(17, 19)}</small></div>
            <div className="col-5" >
              <h3 className="schedule-frequancy">{item.date.slice(20, 23)}</h3>
              <small>{item.date.slice(25, 37)}</small>
            </div>
          </div>
          <div style={{ paddingLeft: "5%" }} className="col-5">
            <span className="schedule-subtitle">Time & Tokens</span>
            {item.slots.map((i) => {
              return (
                <span>
                  {/*<span className='slot-data'>{item.id}</span>*/}
                  <span style={{ fontWeight: "500" }} className="schedule-subtitle">
                    {i.from_time}&nbsp;-&nbsp;{i.to_time}&nbsp;&nbsp;-
                    {i.token}&nbsp;Tokens{i.overbooking_count === 0 ? "" : "(+" + i.overbooking_count + ")"}.
                    {slotCancelledId.length > 0 && isSlotCancelled(i.slot_id, item.from_date, item.to_date, slotCancelled) ? (
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="bottom"
                        overlay={
                          <Tooltip className="my-tooltip" id="button-tooltip">
                            <div>
                              Cancelled dates
                              {CancelledDates.map((item) => {
                                return <div>{item.date}</div>
                              })}

                            </div>

                          </Tooltip>
                        }
                      >
                        <i
                          style={{
                            color: "red",
                            paddingLeft: "1%",
                            paddingTop: "2%",
                            fontSize: "10px"

                          }}
                          onClick={() => getCancelledDates(i.slot_id, item.from_date, item.to_date)}
                          className="fa fa-circle"
                        >&nbsp;Click Here..</i>
                      </OverlayTrigger>
                    ) : ""}</span>

                </span>
              );
            })}
          </div>
          <div className="col-3" style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap' }}>
            <div className="row" style={{ display: "flex", height: 'fit-content' }}>
              <div className={item.days.includes('0') ? "consult-days col-3" : "non-consult-days col-3"}>M</div>
              <div className={item.days.includes('1') ? "consult-days col-3" : "non-consult-days col-3"}>T</div>
              <div className={item.days.includes('2') ? "consult-days col-3" : "non-consult-days col-3"}>W</div>
              <div className={item.days.includes('3') ? "consult-days col-3" : "non-consult-days col-3"}>T</div>
              <div className={item.days.includes('4') ? "consult-days col-3" : "non-consult-days col-3"}>F</div>
              <div className={item.days.includes('5') ? "consult-days col-3" : "non-consult-days col-3"}>S</div>
              <div className={item.days.includes('6') ? "consult-days col-3" : "non-consult-days col-3"}>S</div>

            </div><br />


          </div>

        </div>


      </div>
    );
  });

  return (
    <>
      <div style={{ margin: 'auto', justifyContent: 'center' }} className="row">


        <div className="col-9 " style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0 5%' }}>
          <h4 className="title-of-page"> Schedules </h4>
          <div className="consultation">
            <div className="col-9">
              <AddPayment onSuccess={onSuccess} />
            </div>
            <div className="col-3" style={{ alignContent: 'center' }}>
              <button
                onClick={() => { paymentDone !== "0" ? setAddScheduleShow(true) : alert.error("Please add fee for consultation") }}
                className="add-schedule-btn"
              >
                Add Schedule
              </button>
            </div>
          </div>
          <div className="">
            {display.length > 0 ? (
              display
            ) : (
              <><div className="title-of-page">
                <i style={{ color: "#2962ff" }} class="fa fa-calendar fa-4x"></i>
                <h6 className=" no-schedule ">
                  No Schedules Available at the Moment.
                </h6>
              </div>
              </>
            )}

            <br />


          </div>
          {rescheduleShow ? (
            <ReschedulePopup
              show={rescheduleShow}
              detail={detail}
              onHide={() => setRescheduleShow(false)}
            />
          ) : (
            ""
          )}

          {addScheduleShow ? (
            <AddSchedulePopup
              show={addScheduleShow}
              onHide={() => setAddScheduleShow(false)}
            />
          ) : (
            ""
          )}

          {confirmShow ? (
            <ConfirmPopUp
              show={confirmShow}

              onHide={() => setConfirmShow(false)}
            />
          ) : null}


          {show ? (
            <CancelSlotPopUp show={show} onHide={() => setShow(false)} />
          ) : null}

        </div>
      </div>
    </>
  );
}

export default ScheduleList;
