import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL } from "../../../Global";
import printJS from 'print-js'
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import moment from 'moment'
import { useAlert } from "react-alert";
import pv from "../../../assets/images/Peace-Village-Logo.png";
function PaymentInvoiceLiability(props) {
  // console.log(props.invoiceData,"props.invoiceData")
  const [totalOrderAmount, settotalOrderAmount] = useState(0)
  const [invoiceData, setInvoiceData] = useState(props.invoiceData);
  const [isSaving, setIsSaving] = useState(false);
  const [method, setMethod] = useState('')
  const [payment_to, setpayment_to] = useState('consultation')
  const alert = useAlert();
  const [quantity1, setQuantity] = useState([]);
  const [service, setService] = useState([]);
  const [type, setType] = useState([]);
  const [discount, setDiscount] = useState(0.0);
  const [Ids, setID] = useState([]);
  const [error, setError] = useState({})
  const [reference, setreference] = useState("")
  const [donation_to, setDonation_to] = useState("shelter")
  const [showreference, setShowreference] = useState(false)
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );

  useEffect(() => {


    invoiceData.map((item) => {

      setID(item.hosp_service_id)
      setQuantity(item.quantity);
      setService(item.item_service_name)
      setType(item.order_type)
    })

  }, [])


  const handleMethod = (e) => {
    setMethod(e.target.value)
    if (e.target.value === "upi") {
      setShowreference(true)
      setError((currentstate) => ({
        ...currentstate,
        reference: 'Enter payment reference',
      }))
    }
    else {
      setShowreference(false)
      setError((currentstate) => ({
        ...currentstate,
        reference: '',
      }))
    }
  }
  const handlePayement = (e) => {
    setpayment_to(e.target.value)
  }


  const itemsArray = type === "master_medicine" ? invoiceData.map(item => ({ item: item.hosp_service_id, quantity: parseInt(item.quantity) })) : ""

  const handleSave = async () => {
    document.getElementById("save-btn").disabled = true;

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');
    if (method === "upi" && reference === "") {
      setError((currentstate) => ({
        ...currentstate,
        reference: 'Enter reference',
      }))
    }
    else {
      const dataToUpdate = {
        payment_mode: method,
        paid_amount: payment_to === "consultation" ? (service === "registration" || service === "Registration" ? parseFloat(invoiceData[0].amount) : parseFloat(totalOrderAmount)) : 0,
        donation_to:payment_to === "consultation"?"":donation_to,
        patient_id: props.id,
        paid_for: type === "master_labtest" ? "test" : type === "master_scan" ? "scan" : type === "master_medicine" ? "medicine" : type === "misc" ? "misc" : type === "master_procedure" ? "procedure" : type === "registration" ? "registration" : type === "master_consumable" ? "consumables" : "",
        paid_status: "paid",
        items: type === "master_medicine" ? itemsArray : [Ids],
        donation: payment_to === "consultation" ? 0 : parseFloat(totalOrderAmount),
        bill_discount: parseFloat((totalOrderAmount * discount) / 100),
        payment_reference: reference,

      }

      if (isSaving) {
        return;
      }
      setIsSaving(true);
      try {
        const res = await axios.post(`${APIURL}/api/v1/service-provider/hospital-payments/?master_pay_id=${props.master_id}`, dataToUpdate,
          {
            headers: {
              'Content-Type': 'application/json',
              "Authorization": 'Token ' + str
            }
          })
          .then(res => {

            if (res.data.status === "success") {
              alert.success("Bill Generated")
              handlePrint()
              props.dataHandle1();
            } else {
              alert.error(res.data.message)
            }

          })
      } catch (err) {
        alert.error('Error in Data Submission.Please try again')

      }
      finally {
        setIsSaving(false);
      }
      document.getElementById("save-btn").disabled = false;
    }
  }


  const handlePrint = () => {

    printJS({
      printable: 'print-invoice',
      type: 'html',
      targetStyles: ['*'],
    })

  }
  useEffect(() => {if(payment_to === "donation"){
    setDiscount(0.0)
  }},[payment_to])

  useEffect(() => {
    let totalAmount = props.invoiceData.reduce((accumulator, item) => {
      const discountedAmount = item.amount - (item.amount * item.discount / 100);
      const vatAmount = discountedAmount * (item.vat / 100);
      return accumulator + discountedAmount + vatAmount;
    }, 0);
    const finalTotal = totalAmount - (totalAmount * discount / 100);

    settotalOrderAmount(finalTotal);

  }, [props.invoiceData, discount]);

  return (<div >
<div id='print-invoice'>
<div className='row'>
<div className='col-9'> 
 <div style={{display:"flex",}}><img src={pv}  width="15%" height="15%" alt="image" />&nbsp;&nbsp;
  <div><h3 style={{color:"#be9a7a",fontSize:"18px",paddingTop:"5%"}} className="invoice-heading invoice-heading-normal"><b>{login_datas.hospital_name}</b></h3></div>
</div>
<div style={{textAlign:"left"}}>
           
              <small className="invoice-heading invoice-heading-normal">{login_datas.hospital_state},{login_datas.hospital_location},{login_datas.hospital_street}</small>
              <small className="invoice-heading invoice-heading-normal">{login_datas.hospital_mobile}</small><br/>
              <small className="invoice-heading invoice-heading-normal">{login_datas.hospital_email}</small>
             </div>
</div>

<div className='col-2'>
<h2 style={{color:"blue"}} className="title-of-tasks"> INVOICE  </h2>
    </div>
                </div><br/>
    <div className='row'>
      <div className='col-9'>
        <div style={{ textAlign: "left" }}>
          <h6 style={{ color: "lightblue" }}>Issued to:</h6>
          <small className="invoice-heading invoice-heading-normal">Patient: {props.name}</small><br />
          <small className="invoice-heading invoice-heading-normal">Patient ID {props.id}</small><br />
          {props.mobile && <small className="invoice-heading invoice-heading-normal">{props.mobile}</small>}
        </div>


      </div>
      <div className='col-3'>
        <div style={{ textAlign: "left" }}>

          <small className="invoice-heading invoice-heading-normal">Date: {moment().format('DD-MM-YYYY')}</small><br />

        </div>


      </div>
    </div>
    <div style={{ marginBottom: "1%" }} className="row">
      <div style={{ backgroundColor: "rgb(13, 197, 197)", color: "white", border: "1px solid white", borderRadius: "5px", }} className="col-4"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}> Name</div></div>
      {quantity1 && <div style={{ backgroundColor: "rgb(13, 197, 197)", color: "white", border: "1px solid white", borderRadius: "5px", }} className="col-1"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}>Qty</div></div>}
      <div style={{ backgroundColor: "rgb(13, 197, 197)", color: "white", border: "1px solid white", borderRadius: "5px", }} className="col-2"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}>Amount</div></div>
      <div style={{ backgroundColor: "rgb(13, 197, 197)", color: "white", border: "1px solid white", borderRadius: "5px", }} className="col-1"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}>VAT%</div></div>
      <div style={{ backgroundColor: "rgb(13, 197, 197)", color: "white", border: "1px solid white", borderRadius: "5px", }} className="col-2"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}>Discount% </div></div>
      <div style={{ backgroundColor: "rgb(13, 197, 197)", color: "white", border: "1px solid white", borderRadius: "5px", }} className="col-2"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}>Total</div></div>
    </div>
    <div style={{ marginBottom: "1%" }} className="row">
      {invoiceData.map(item => {
        let discountedAmount2 = (item.amount * item.discount) / 100;
        let discountedTotalAmount2 = item.amount - (discountedAmount2);
        let vatAmount2 = discountedTotalAmount2 * (item.vat / 100)
        let totalAmount2 = discountedTotalAmount2 + vatAmount2;

        return (<>

          <div style={{ backgroundColor: "#e4dd9f", border: "1px solid white", borderRadius: "5px", }} className="col-4"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}>{item.item_service_name}</div></div>
          {quantity1 && <div style={{ backgroundColor: "#e4dd9f", border: "1px solid white", borderRadius: "5px", }} className="col-1"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}>{item.quantity}</div></div>}
          <div style={{ backgroundColor: "#e4dd9f", border: "1px solid white", borderRadius: "5px", }} className="col-2"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}>{item.amount}</div></div>
          <div style={{ backgroundColor: "#e4dd9f", border: "1px solid white", borderRadius: "5px", }} className="col-1"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}>{item.vat}</div></div>
          <div style={{ backgroundColor: "#e4dd9f", border: "1px solid white", borderRadius: "5px", }} className="col-2"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}>{item.discount}</div></div>
          <div style={{ backgroundColor: "#e4dd9f", border: "1px solid white", borderRadius: "5px", }} className="col-2"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}> {service === "registration" || service === "Registration" ? item.amount : totalAmount2}</div></div>
        </>
        )
      })}
    </div>
    <br />
    <div className='row'>
      <div className='col-7'>
        <div className='input1 ' >
          <div className='row' style={{ width: "100%" }}>  <span className='text-muted font-weight-bold col-4'>Payment to :</span>
            <div className="col-4">
              <input
                type="radio"
                id="consultation"
                name="payment_to"
                value="consultation"
                checked={payment_to === "consultation"}
                onClick={handlePayement}
              />&nbsp;<div style={{ paddingTop: "9%" }}>
                <label htmlFor="consultation">Consultation</label>
              </div>
            </div>

            <div className="col-4">
              <input
                type="radio"
                id="donation"
                name="payment_to"
                value="donation"
                checked={payment_to === "donation"}
                onClick={handlePayement}
              />&nbsp;<div style={{ paddingTop: "9%" }}>
                <label htmlFor="donation">Donation</label>
              </div>

            </div>
          </div>
        </div><br />
        {payment_to === "consultation" ? <div className='input1 ' >

          <span style={{ textAlign: "left" }} className='text-muted font-weight-bold col-4'>Discount in % :</span>&nbsp;
          
          <input

            type="number"
            maxLength="50"
            name="discount"
            disabled={payment_to === "donation"}
            style={{
              border: "1px solid #CCCCCC",
              borderRadius: " 8px",
              width:"60%",
              marginLeft:"3%"
            }}
            className='col-8'
            value={payment_to === "consultation" ? discount : 0}
            onChange={(e) => {

              setDiscount(e.target.value > 0&&e.target.value<100.1 ? e.target.value : "")

            }}
          />
         

        </div> :

          <div className='input1 ' >
            <div className='row' style={{ width: "100%" }}>  <span className='text-muted font-weight-bold col-4'>Donation to :</span>
              <div className="col-4">
                <input
                  type="radio"
                  id="shelter"
                  name="donation_to"
                  value="shelter"
                  checked={donation_to === "shelter"}
                  onClick={(e) => {

                    setDonation_to("shelter")
      
                  }}
                />&nbsp;<div style={{ paddingTop: "9%" }}>
                  <label htmlFor="shelter">Shelter home</label>
                </div>
              </div>


              <div className="col-4">
                <input
                  type="radio"
                  id="trust"
                  name="donation_to"
                  value="trust"
                  checked={donation_to === "trust"}
                  onClick={(e) => {

                    setDonation_to("trust")
      
                  }}
                />&nbsp;<div style={{ paddingTop: "9%" }}>
                  <label htmlFor="trust">Trust</label>

                </div>
              </div>
            </div>
          </div>}


      </div>
      <div className='col-5 input1' style={{ height: "110px", display: "block" }}>
        <div> <span style={{ textAlign: "left" }} className='text-muted font-weight-bold '> Total Payable:</span></div><br />
        <div> <h3 ><span className=""><i class="fa fa-inr" aria-hidden="true"></i></span>{service === "registration" || service === "Registration" ? invoiceData[0].amount : totalOrderAmount}</h3></div>
      </div>
    </div>


    <br />

    <h6 className="text-right">Thanks,</h6>
    <h6 className="text-right">{login_datas.name}</h6><br />
    </div>
    <div className='row'>
      <div className='col-8'>
        <div className='flex-row invoice-radio'>
          <span className='text-muted font-weight-bold'>Payment method :</span>
          <input type='radio' className='1me' checked={method === 'card'} id='card' value='card' style={{ display: "none" }} onChange={handleMethod} />
          <label htmlFor='card' className='invoice-label'>
            Card
          </label>

          <input type='radio' className='hideme' checked={method === 'cash'} id='cash' value='cash' onChange={handleMethod} />
          <label htmlFor='cash' className='invoice-label'>
            Cash
          </label>

          <input type='radio' className='hideme' checked={method === 'upi'} id='benefit_pay' value='upi' onChange={handleMethod} />
          <label htmlFor='benefit_pay' className='invoice-label'>
            UPI
          </label>
        </div>
        {showreference && <div className='row' style={{ width: "100%" }}>  <span className='text-muted font-weight-bold col-4'></span> <input

          type="text"
          maxLength="50"
          placeholder='Enter payment reference'
          name="payment_reference"
          className={`input1 ${error.reference ? 'error-border col-8' : 'col-8'}`}
          value={reference}
          onChange={(e) => {
            setreference(e.target.value);
            if (e.target.value !== "") {
              setError((currentstate) => ({
                ...currentstate,
                reference: '',
              }))
            }
            else {
              setError((currentstate) => ({
                ...currentstate,
                reference: 'Enter reference',
              }))
            }

          }}
        /></div>}
      </div>
      <div className='col-4'>
        <button className="btn btn-primary btn-col btn-square" disabled={method === '' || (method === "upi" && reference === "")} id="save-btn" onClick={handleSave}> Save and Print </button>
      </div>
    </div>
    <br />
    <div className="do-not-print text-center" id="pr">


      <small style={{ color: "grey" }}>** If in case 'Print' does not work, please cross check if popup blocker is switched 'off' in your Browser settings.</small>

    </div>



  </div>
  )
}

export default PaymentInvoiceLiability
